import { Box, Button, Flex, Image, Spinner, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ProductsInterface, SidebarProps } from "@/types/interfaces";
import { useState, useEffect, useContext } from "react";
import { AttributesContext, ShowModalContext } from "@/utils/contexts";

const Sidebar = ({
	handleShowLoan,
	showLoan,
	myRef,
	arrowDown,
	arrowUp,
}: SidebarProps) => {
	const [activeProducts, setActiveProducts] = useState(JSON.parse(sessionStorage.getItem("products") as string));
	const { setAttribute } = useContext(AttributesContext);
	const { setShowModal } = useContext(ShowModalContext);

	const handleShowModal = (product_id: number | null) => {
		const product_key = "pk_live_DxhIehsdX7VB5bN7qcJBMUpEsNe90Qe8Lhi418XT";

		setShowModal(true);
		setAttribute({ product_key, product_id });
	};

	useEffect(() => {
		const intervalId = setInterval(() => {
		  const productListString = sessionStorage.getItem("products");
		  if (productListString) {
			const productList = JSON.parse(productListString);
			setActiveProducts(productList);
		  }
		}, 3000);

		if (sessionStorage.getItem("products")) {
			clearInterval(intervalId);
		}
	  
		return () => {
			clearInterval(intervalId);
		};
	  }, []);


	return (
		<Box maxW="1280px" p="0">
			<Box
				display={["block", "block", "block", "none", "none"]}
				w="100%"
				h="100%"
				pos="absolute"
				top="70px"
				left="0"
				zIndex="2"
			>
				<Box bg="brand.purple100" w="100%" p="2rem 2.5rem">
					<Box
						display={["block", "block", "block", "none", "none"]}
						alignItems="center"
						justifyContent="space-between"
						w="60%"
						onClick={(e) => e.stopPropagation()}
					>
						<Flex
							cursor="pointer"
							ref={myRef}
							onClick={handleShowLoan}
							mt="2rem"
						>
							<Text
								fontSize="1rem"
								fontWeight="600"
								zIndex="3"
								color="brand.white100"
							>
								Loans
							</Text>
							{!showLoan ? (
								<Image
									ml="0.8rem"
									mt="0.1rem"
									src={arrowDown}
									alt="Arrow Down White"
										loading="lazy"
								/>
							) : (
								<Image
									ml="0.8rem"
									mt="0.1rem"
									src={arrowUp}
									alt="Arrow Down White"
										loading="lazy"
								/>
							)}
						</Flex>

						{showLoan ? (
							<Box
								pos="absolute"
								top="6rem"
								boxShadow="0px 12px 32px rgba(0, 0, 0, 0.2)"
								w="150px"
								p="1rem"
								borderRadius="12px"
								bg="brand.white100"
								lineHeight="2"
								fontWeight="500"
								opacity="1"
								transition="all 2s cubic-bezier(0.075, 0.82, 0.165, 1)"
								zIndex="1"
							>
								{activeProducts ? activeProducts.map((product: ProductsInterface) => (
									<Text
									    key={product.name}
										_hover={{
											color: "brand.purple100",
										}}
										cursor={"pointer"}
										onClick={() => handleShowModal(product.id)}
									>
									  {product.name}
									</Text>
								)) : <Spinner
								thickness='4px'
								speed='0.65s'
								emptyColor='gray.200'
								color='purple'
								size='sm'
								display={"block"}
								margin={"0 auto"}
							  />}
							</Box>
						) : (
							<Box
								pos="absolute"
								top="6rem"
								boxShadow="0px 12px 32px rgba(0, 0, 0, 0.2)"
								w="150px"
								p="1rem"
								borderRadius="12px"
								bg="brand.white100"
								lineHeight="2"
								fontWeight="500"
								pointerEvents="none"
								opacity="0"
								transition="all 1s cubic-bezier(0.075, 0.82, 0.165, 1)"
								zIndex="1"
							>
								{activeProducts?.map((product: ProductsInterface) => (
									<Text
									    key={product.name}
										_hover={{
											color: "brand.purple100",
										}}
										cursor={"pointer"}
										onClick={() => handleShowModal(product.id)}
									>
									  {product.name}
									</Text>
								))}
							</Box>
						)}

						<a href="/#howItWorks">
							<Text
								// onClick={() => setShowSidebar(false)}
								mt="2rem"
								fontSize="1rem"
								fontWeight="600"
								color="brand.white100"
							>
								How It works
							</Text>
						</a>

						<Link to="/faq">
							<Text
								// onClick={() => setShowSidebar(false)}
								mt="2rem"
								fontSize="1rem"
								fontWeight="600"
								color="brand.white100"
							>
								FAQs
							</Text>
						</Link>

						<a href="https://app.snapcash.ng/signup" target="_blank" rel="noreferrer">
							<Button
								border="2px solid #BEC963;"
								borderRadius="8px"
								bgColor="transparent"
								color="brand.white100"
								fontSize="1rem"
								fontWeight="600"
								p="1.5rem 1rem"
								mt="2rem"
								_hover={{
									bgColor: "none",
								}}
								_active={{
									bgColor: "none",
								}}
								_focus={{
									bgColor: "none",
								}}
							>
								Sign Up
							</Button>
						</a>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export { Sidebar };
