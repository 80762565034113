import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { SnapcashButton } from "@/components/button/Button";
import ArrowRightGray from "@/assets/svgs/arrow-right-gray-icon.svg";
import { SliderDataProps } from "@/types/interfaces";


const SliderCard = ({ title, descOne, spacing, rate, setShowModal }: SliderDataProps) => {
	const openModal = () => {
		setShowModal(true);
	};

	return (
		<Box role="group">
			<VStack
				spacing={spacing}
				bg="brand.white200"
				w={[
					"280px!important",
					"370px!important",
					"420px!important",
					"420px!important",
				]}
				borderRadius="20px"
				overflow="hidden"
				mt="3rem"
				_groupHover={{
					bg: "brand.purple100",
					filter: "drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.2))",
					transition: "all .75s cubic-bezier(0.075, 0.82, 0.165, 1)",
				}}
			>
				<Box p="2rem" color="brand.gray600" mr={["0", "0", "3rem", "3rem"]}>
					<Text
						fontSize={["1.5rem", "1.5rem", "2rem", "2rem"]}
						fontWeight="700"
						_groupHover={{
							color: "brand.white100",
							transition: "all .75s cubic-bezier(0.075, 0.82, 0.165, 1)",
						}}
					>
						{title}
					</Text>
					<Box
						fontSize={[".75rem", ".75rem", ".9rem", ".9rem"]}
						fontWeight="400"
						color="brand.gray600"
						_groupHover={{
							color: "brand.white100",
							transition: "all .75s cubic-bezier(0.075, 0.82, 0.165, 1)",
						}}
					>
						<Text>{descOne}</Text>
					</Box>
				</Box>

				<HStack
					display={["block", "block", "flex", "flex"]}
					bg="brand.gray400"
					p="1.5rem"
					w="100%"
					fontWeight="600"
					_groupHover={{
						bg: "brand.purple200",
						transition: "all .75s cubic-bezier(0.075, 0.82, 0.165, 1)",
					}}
					justify="space-between"
				>
					<Text
						color="brand.gray600"
						fontSize={[".9rem", ".9rem", "1rem", "1rem"]}
						_groupHover={{
							color: "brand.white100",
							transition: "all .75s cubic-bezier(0.075, 0.82, 0.165, 1)",
						}}
					>
						{`${rate}% interest rate`}
					</Text>
						<SnapcashButton
							p="1rem"
							w="100%"
							ml="1rem"
							src={ArrowRightGray}
							text="Apply now"
							alt="Apply now"
							bgColor="brand.lime100"
							color="brand.gray600"
							border="none"
							func={openModal}
						/>
				</HStack>
			</VStack>
		</Box>
	);
};

export { SliderCard };
