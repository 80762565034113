import { Box, Button, Image, Text } from "@chakra-ui/react";
import { SnapcashButtonProps } from "@/types/interfaces";

export const SnapcashButton = ({
	p,
	w,
	ml,
	src,
	text,
	alt,
	bgColor,
	color,
	border,
	func
}: SnapcashButtonProps) => {
	return (
		<Box>
			<Button
				borderRadius="8px"
				bgColor={bgColor}
				color={color}
				border={border}
				fontSize={[".8rem", ".9rem", ".9rem"]}
				fontWeight="600"
				w={w}
				p={p}
				h={["1.9rem", "2.5rem", "2.5rem"]}
				_active={{
					bgColor: "none",
				}}
				_hover={{
					bgColor: "none",
				}}
				_focus={{
					bgColor: "none",
				}}
				onClick={() => func && func()}
			>
				<Text>{text}</Text>
				<Image src={src} ml={ml} alt={alt} 	loading="lazy" />
			</Button>
		</Box>
	);
};
