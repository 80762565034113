import { Box, Stack, Text } from "@chakra-ui/react";
import { SliderLayout } from "./SliderLayout";

export const Plan = () => {
	return (
		<Box p="0" bg="brand.white100" overflow="hidden">
			<Stack
				maxW="1280px"
				mx="auto"
				p={["2rem 2rem", "2rem 2rem", "4rem 6rem 4rem"]}
				border="1px solid #fff"
			>
				<Box
					pt={["2rem", "2.5rem", "2.5rem", "2.5rem"]}
					pb={["0", "0rem", "2.5rem"]}
					id="goTo"
				>
					<Box textAlign="center">
						<Text
							fontSize={["1.7rem", "2rem", "2rem", "2rem"]}
							fontWeight="700"
							lineHeight="1.2"
						>
							Snaps tailored for you
						</Text>
					</Box>

					<Box>
						<SliderLayout />
					</Box>
				</Box>
			</Stack>
		</Box>
	);
};
