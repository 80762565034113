import { Box, HStack, Image, Stack, Text } from "@chakra-ui/react";
import googlePlay from "@/assets/images/playstore.png";
import appleStore from "@/assets/images/app-store.png";
import webApp from "@/assets/images/web-app.png";
import { HeroAnimation } from "./HeroAnimation";

export const Hero = () => {
	return (
		<Box p="0" bg="brand.purple100" overflow="hidden">
			<Stack
				maxW="1280px"
				mx="auto"
				direction={["column", "column", "row", "row"]}
				p={["4rem 1rem 4rem", "4rem 2rem 4rem", "5rem 3rem 8rem"]}
				// h="calc(100vh - 12vh)"
				pos="relative"
			>
				<Box
					pt={[".5rem", "2.5rem", "2.5rem", "3rem"]}
					pb={["0", "0rem", "2.5rem"]}
					w={["100%", "100%", "100%", "100%", "43%"]}
				>
					<Box>
						<Text
						    as={"h1"}
							fontSize={["1.7rem", "2rem", "3rem", "4.75rem", "4.2rem"]}
							fontWeight="700"
							lineHeight="1.1"
							color="brand.white100"
						>
							Tailor-made loans for you!
						</Text>
						<Text
							fontSize={["1rem", "1.5rem"]}
							fontWeight="700"
							color="brand.white100"
						>
							Zero Paperwork, Zero stress, Zero{" "}
							<Text as="span" color="brand.lime100">
								delays.
							</Text>
						</Text>
					</Box>
					<HStack spacing=".8rem" mt="4rem">
						<a
							href="https://apps.apple.com/ng/app/snapcash-loans-in-a-snap/id1634947408"
							target="_blank"
							rel="noreferrer"
						>
							<Image src={appleStore} w="133px" alt="App Store" 	loading="lazy" />
						</a>
						<a
							href="https://play.google.com/store/apps/details?id=com.snapcashsterling"
							target="_blank"
							rel="noreferrer"
						>
							<Image src={googlePlay} w="133px" alt="Google Play" 	loading="lazy" />
						</a>
						<a href="https://app.snapcash.ng" target="_blank" rel="noreferrer">
							<Image src={webApp} w="133px" alt="Web App" 	loading="lazy" />
						</a>
					</HStack>
				</Box>

				<HeroAnimation />
			</Stack>
		</Box>
	);
};
