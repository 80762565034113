import {
	Box,
	Stack,
	Text,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
} from "@chakra-ui/react";
import MainLayout from "@/layouts/MainLayout";
import { Privacy } from "@/components/legal/Privacy";
import { Terms } from "@/components/legal/Terms";
import { License } from "@/components/legal/License";

const Legal = () => {
	return (
		<MainLayout>
			<Box p="0" bg="brand.purple100">
				<Stack
					maxW="1280px"
					mx="auto"
					p={["0rem 1rem 2rem", "2rem 2rem 2rem", "4rem 3rem 4rem"]}
				>
					<Text
						fontSize={["2rem", "2rem", "3rem"]}
						color="brand.white100"
						fontWeight="700"
					>
						Legal
					</Text>
				</Stack>
			</Box>

			<Stack
				maxW="1280px"
				mx="auto"
				p={["0rem 1rem 0rem", "2rem 2rem 0rem", "6rem 3rem 4rem"]}
			>
				<Tabs
					orientation="vertical"
					justifyContent="space-around"
					flexDir={["column", "column", "row"]}
				>
					<Box w={["100%", "100%", "80%"]} order={["1", "1", "0"]}>
						<TabPanels>
							<TabPanel id="privacy">
								<Privacy />
							</TabPanel>
							<TabPanel id="terms">
								<Terms />
							</TabPanel>
							<TabPanel>
								<License />
							</TabPanel>
						</TabPanels>
					</Box>

					<TabList
						h="10%"
						borderColor="brand.dark100"
						my="2rem"
					>
						<Tab
							justifyContent="start"
							fontWeight="500"
							fontSize={[".8rem", ".8rem", "1rem", "1.2rem"]}
							_hover={{
								bg: "none",
							}}
							_focus={{
								boxShadow: "none",
							}}
							_selected={{
								color: "brand.purple100",
								borderColor: "brand.purple100",
								borderLeftWidth: "3.5px",
								// h: "12px",
							}}
						>
							Privacy Policy
						</Tab>
						<Tab
							id="terms"
							justifyContent="start"
							fontWeight="500"
							fontSize={[".8rem", ".8rem", "1rem", "1.2rem"]}
							_hover={{
								bg: "none",
							}}
							_focus={{
								boxShadow: "none",
							}}
							_selected={{
								color: "brand.purple100",
								borderColor: "brand.purple100",
								borderLeftWidth: "3.5px",
								// h: "12px",
							}}
						>
							Terms Of Use
						</Tab>
						<Tab
							justifyContent="start"
							fontWeight="500"
							fontSize={[".8rem", ".8rem", "1rem", "1.2rem"]}
							_hover={{
								bg: "none",
							}}
							_focus={{
								boxShadow: "none",
							}}
							_selected={{
								color: "brand.purple100",
								borderColor: "brand.purple100",
								borderLeftWidth: "3.5px",
								// h: "12px",
							}}
						>
							License
						</Tab>
					</TabList>
				</Tabs>
			</Stack>
		</MainLayout>
	);
};

export default Legal;
