import * as React from "react";


export interface MagicAttributes {
  product_key: string | null;
  product_id: number | null;
}
interface MagicFrameProps extends MagicAttributes {
  close: () => void;
}

const MagicFrame: React.FC<MagicFrameProps> = (props: MagicFrameProps) => {
  const baseUrl = "https://invitations.lendsqr.com";
  const url = `${baseUrl}/init?key=${props.product_key}&product_id=${props.product_id}`;
  
  return (
    <div className="magic-container">
      <div className="magic-modal">
        <div className="cancel-logo">
          <img
            src="https://lsq-email-assets.s3.us-east-2.amazonaws.com/cancel.svg"
            onClick={props.close}
            width={24}
            loading="lazy"
          />
        </div>
        <iframe
          id="lsq-widget-iframe"
          allow={`midi; geolocation ${baseUrl}; camera ${baseUrl}`}
          src={url}
          style={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: 0,
          }}
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </div>
  );
};

export default MagicFrame;
