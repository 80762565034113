import { useEffect, useState } from "react";
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	HStack,
	Image,
	Stack,
	Text,
} from "@chakra-ui/react";
import googlePlay from "@/assets/images/playstore.png";
import appleStore from "@/assets/images/app-store.png";
import webApp from "@/assets/images/web-app.png";
import PhoneFrame from "@/assets/svgs/how-phone-frame.svg";
// import PhoneFrameImgFirstOne from "@/assets/svgs/how-phone-frame-img-first-1.svg";
// import PhoneFrameImgFirstTwo from "@/assets/svgs/how-phone-frame-img-first-2.svg";
// import PhoneFrameImgFirstThree from "@/assets/svgs/how-phone-frame-img-first-3.svg";
import PhoneFrameImgSecondOne from "@/assets/svgs/how-phone-frame-img-second-1.svg";
// import PhoneFrameImgSecondTwo from "@/assets/svgs/how-phone-frame-img-second-2.png";
import PhoneFrameImgThirdOne from "@/assets/svgs/how-phone-frame-img-third-1.svg";
// import PhoneFrameImgThirdTwo from "@/assets/svgs/how-phone-frame-img-third-2.png";
// import PhoneFrameImgThirdThree from "@/assets/svgs/how-phone-frame-img-third-3.svg";

export const HowItWorks = () => {
	const [step, setStep] = useState(1);

	useEffect(() => {
		const interval = setInterval(() => {
			setStep((step) => (step < 2 ? step + 1 : 1));
		}, 3000);
		return () => clearInterval(interval);
	}, []);

	return (
		<Box p="0" bg="brand.purple100" overflow="hidden">
			<Stack
				maxW="1280px"
				mx="auto"
				direction={["column", "column", "row", "row"]}
				p={["2rem 1rem 4rem", "2rem 2rem 4rem", "4rem 3rem 6rem"]}
				pos="relative"
			>
				<Box
					pb={["0", "0rem", "2.5rem"]}
					w={["100%", "100%", "100%", "63%", "56%"]}
					id="howItWorks"
				>
					<Box>
						<Text
							fontSize={["1.7rem", "2rem", "2rem", "4.25rem"]}
							fontWeight="700"
							color="brand.white100"
						>
							How it works
						</Text>

						<Box>
							<Accordion
								allowToggle
								defaultIndex={[0, 1, 2]}
								allowMultiple
								bg="brand.white100"
								w={["100%", "100%", "100%", "81%", "67%"]}
								borderRadius="10px"
								m="3rem 0 6rem 0"
							>
								<AccordionItem
									borderTop="none"
									borderBottom="1px solid"
									borderBottomColor="brand.purple100"
								>
									<h2>
										<AccordionButton
											pb="0"
											_hover={{
												bg: "none",
											}}
											_focus={{
												bg: "none",
											}}
										>
											<Box
												flex="1"
												textAlign="left"
												fontSize="1.1rem"
												fontWeight="600"
											>
												1. Download the App
											</Box>
											<AccordionIcon fontSize="2rem" />
										</AccordionButton>
									</h2>
									<AccordionPanel
										pb={4}
										color="brand.gray600"
										fontSize=".85rem"
										fontWeight="400"
										mr="3rem"
										pt="0"
									>
										Download SnapCash on Play Store or App Store and sign up!
										You can also sign up on the web app!
									</AccordionPanel>
								</AccordionItem>
								<AccordionItem
									borderTop="none"
									borderBottom="1px solid"
									borderBottomColor="brand.purple100"
								>
									<h2>
										<AccordionButton
											pb="0"
											_hover={{
												bg: "none",
											}}
											_focus={{
												bg: "none",
											}}
										>
											<Box
												flex="1"
												textAlign="left"
												fontSize="1.1rem"
												fontWeight="600"
											>
												2. Sign up and apply for a loan
											</Box>
											<AccordionIcon fontSize="2rem" />
										</AccordionButton>
									</h2>
									<AccordionPanel
										pb={4}
										color="brand.gray600"
										fontSize=".85rem"
										fontWeight="400"
										mr="3rem"
										pt="0"
									>
										Apply for a loan on your dashboard and provide the required
										information.
									</AccordionPanel>
								</AccordionItem>
								<AccordionItem border="none">
									<h2>
										<AccordionButton
											pb="0"
											_hover={{
												bg: "none",
											}}
											_focus={{
												bg: "none",
											}}
										>
											<Box
												flex="1"
												textAlign="left"
												fontSize="1.1rem"
												fontWeight="600"
											>
												3. Get credited in a Snap!
											</Box>
											<AccordionIcon fontSize="2rem" />
										</AccordionButton>
									</h2>
									<AccordionPanel
										pb={4}
										color="brand.gray600"
										fontSize=".85rem"
										fontWeight="400"
										mr="2rem"
										pt="0"
									>
										Accept your loan offer and have your account credited within
										minutes.
									</AccordionPanel>
								</AccordionItem>
							</Accordion>
						</Box>
					</Box>
					<HStack spacing=".8rem" mt="1.5rem">
						<a
							href="https://apps.apple.com/ng/app/snapcash-loans-in-a-snap/id1634947408"
							target="_blank"
							rel="noreferrer"
						>
							<Image src={appleStore} w="133px" alt="App Store" 	loading="lazy" />
						</a>
						<a
							href="https://play.google.com/store/apps/details?id=com.snapcashsterling"
							target="_blank"
							rel="noreferrer"
						>
							<Image src={googlePlay} w="133px" alt="Google Play" 	loading="lazy" />
						</a>
						<a href="https://app.snapcash.ng" target="_blank" rel="noreferrer">
							<Image src={webApp} w="133px" alt="Web App" 	loading="lazy" />
						</a>
					</HStack>
				</Box>
				<Box display={["none", "none", "none", "block"]}>
					<Box>
						<Box pos="relative" zIndex="2">
							<Image src={PhoneFrame} alt="Hero Phone Image" 	loading="lazy" />
						</Box>

						{/* {step === 3 && (
							<Box>
								<Image
									src={PhoneFrameImgFirstOne}
									pos={[
										"initial",
										"absolute",
										"absolute",
										"absolute",
										"absolute",
									]}
									top={["11.5%", "11.5%", "11.5%", "11.5%", "11.5%"]}
									right={["7.8%", "7.8%", "7.8%", "7%", "20.35%"]}
									alt="Hero Phone Image"
										loading="lazy"
								/>
								<Image
									src={PhoneFrameImgFirstTwo}
									pos={[
										"initial",
										"absolute",
										"absolute",
										"absolute",
										"absolute",
									]}
									top={["45%", "45%", "45%", "45%", "30%"]}
									right={["0%", "0%", "0%", "0%", "7%"]}
									zIndex="2"
									alt="Hero Phone Image"
										loading="lazy"
								/>
								<Image
									src={PhoneFrameImgFirstThree}
									pos={[
										"initial",
										"absolute",
										"absolute",
										"absolute",
										"absolute",
									]}
									top={["65%", "65%", "65%", "65%", "54%"]}
									right={["-0.9%", "-0.9%", "-0.9%", "-0.9%", "7.1%"]}
									zIndex="2"
									alt="Hero Phone Image"
										loading="lazy"
								/>
							</Box>
						)} */}

						{step === 1 && (
							<Box>
								<Image
									src={PhoneFrameImgSecondOne}
									pos={[
										"initial",
										"absolute",
										"absolute",
										"absolute",
										"absolute",
									]}
									top={["10.25%", "10.25%", "10.25%", "10.25%", "10.25%"]}
									right={["8.1%", "8.1%", "8.1%", "8.1%", "20.4%"]}
									alt="Hero Phone Image"
									loading="lazy"
								/>

								{/* <Image
									src={PhoneFrameImgSecondTwo}
									pos={[
										"initial",
										"absolute",
										"absolute",
										"absolute",
										"absolute",
									]}
									top="43%"
									right="-2%"
									zIndex="1"
									alt="Hero Phone Image Icon"
										loading="lazy"
								/> */}
							</Box>
						)}

						{step === 2 && (
							<Box>
								<Image
									src={PhoneFrameImgThirdOne}
									pos={[
										"initial",
										"absolute",
										"absolute",
										"absolute",
										"absolute",
									]}
									top="10.3%"
									right={["8.1%", "8.1%", "8.1%", "8.1%", "20.29%"]}
									alt="Hero Phone Image"
									loading="lazy"
								/>
								{/* <Image
									src={PhoneFrameImgThirdTwo}
									pos={[
										"initial",
										"absolute",
										"absolute",
										"absolute",
										"absolute",
									]}
									top="36%"
									right={["0%", "0%", "0%", "0%", "13.1%"]}
									zIndex="2"
									alt="Hero Phone Image"
										loading="lazy"
								/>
								<Image
									src={PhoneFrameImgThirdThree}
									pos={[
										"initial",
										"absolute",
										"absolute",
										"absolute",
										"absolute",
									]}
									top="56%"
									right={["-0.9%", "-0.9%", "-0.9%", "-0.9%", "6.3%"]}
									zIndex="2"
									alt="Hero Phone Image"
								/> */}
							</Box>
						)}
					</Box>
				</Box>
			</Stack>
		</Box>
	);
};
