import { Box, Image, Stack, Text, VStack } from "@chakra-ui/react";
import { SnapcashButton } from "@/components/button/Button";
import ArrowRightBlack from "@/assets/svgs/arrow-right-black-icon.svg";
import ArrowRightWhite from "@/assets/svgs/arrow-right-white-icon.svg";
import {
	AccessDataOne,
	AccessDataTwo,
	AccessDataThree,
	AccessDataFour,
} from "@/utils/AccessData";

export const Access = () => {
	return (
		<Box p="0" bg="brand.gray500">
			<Stack
				maxW="1280px"
				mx="auto"
				overflow="hidden"
				direction={["column", "column", "column", "column", "row"]}
				alignItems={[
					"flex-start",
					"flex-start",
					"flex-start",
					"flex-start",
					"center",
				]}
				p={["2rem 1rem", "2rem 1rem", "2rem 3rem"]}
			>
				<Box pb={["1rem", "2rem", "2.5rem"]}>
					<VStack
						alignItems="flex-start"
						justifyContent="center"
						w={["100%", "100%", "100%", "100%", "75%"]}
						spacing="2.5rem"
					>
						<Text
							fontSize={["1.7rem", "2rem", "3.5rem", "4rem"]}
							fontWeight="700"
							lineHeight="1.1"
						>
							{AccessDataOne.title}
						</Text>
						<Text
							color="brand.dark100"
							fontWeight="400"
							fontSize="1.1rem"
							m="2.5rem 4rem 2rem 0"
							lineHeight="1.2"
						>
							{AccessDataOne.desc}
						</Text>

						<a href="https://app.snapcash.ng" target="_blank" rel="noreferrer">
							<SnapcashButton
								bgColor="transparent"
								color="brand.dark100"
								border="2px solid #BEC963"
								text={AccessDataOne.buttonText}
								src={ArrowRightBlack}
								p="1rem .7rem"
								ml="9rem"
								w=""
								alt="Arrow down"
							/>
						</a>
					</VStack>
				</Box>

				<Box
					w={["100%", "100%", "100%", "66%"]}
					mx={["auto", "auto", "auto", "auto", "0"]}
				>
					<Image
						src={AccessDataOne.backgroundImage}
						alt="Access Background Image One"
						loading="lazy"
					/>
				</Box>
			</Stack>
		</Box>
	);
};

export const Document = () => {
	return (
		<Box p="0" bg="brand.white100">
			<Stack
				maxW="1280px"
				mx="auto"
				overflow="hidden"
				direction={["column", "column", "column", "column", "row"]}
				alignItems="center"
				p={["2rem 1rem", "2rem 1rem", "2rem 3rem"]}
			>
				<Box
					w={["100%", "100%", "100%", "100%", "60%"]}
					order={["1", "1", "1", "1", "0"]}
				>
					<Image
						mx={["auto", "auto", "auto", "auto", "0"]}
						src={AccessDataTwo.backgroundImage}
						alt="Access Background Image Two"
						loading="lazy"
					/>
				</Box>

				<Box
					pb={["1rem", "2rem", "2.5rem"]}
					w={["100%", "100%", "100%", "100%", "47%"]}
				>
					<VStack
						mx="auto"
						alignItems="flex-start"
						justifyContent="center"
						spacing="2.5rem"
					>
						<Text
							fontSize={["1.7rem", "2rem", "3.5rem", "4rem"]}
							fontWeight="700"
							lineHeight="1.1"
						>
							{AccessDataTwo.title}
						</Text>
						<Text
							color="brand.dark100"
							fontWeight="400"
							fontSize="1.1rem"
							lineHeight="1.2"
						>
							{AccessDataTwo.desc}
						</Text>

						<a href="https://app.snapcash.ng" target="_blank" rel="noreferrer">
							<SnapcashButton
								bgColor="transparent"
								color="brand.dark100"
								border="2px solid #BEC963"
								text={AccessDataTwo.buttonText}
								src={ArrowRightBlack}
								p="1rem .7rem"
								ml="8.5rem"
								w=""
								alt="Arrow down"
							/>
						</a>
					</VStack>
				</Box>
			</Stack>
		</Box>
	);
};

export const Agreeable = () => {
	return (
		<Box p="0" bg="brand.gray500">
			<Stack
				maxW="1280px"
				mx="auto"
				overflow="hidden"
				direction={["column", "column", "column", "column", "row"]}
				alignItems={[
					"flex-start",
					"flex-start",
					"flex-start",
					"flex-start",
					"center",
				]}
				justifyContent="space-between"
				p={["2rem 1rem", "2rem 1rem", "2rem 3rem"]}
			>
				<Box pb={["1rem", "2rem", "2.5rem"]}>
					<VStack
						alignItems="flex-start"
						justifyContent="center"
						w={["100%", "100%", "100%", "100%", "70%"]}
						spacing="2.5rem"
					>
						<Text
							fontSize={["1.5rem", "2rem", "3.5rem", "4rem"]}
							fontWeight="700"
							lineHeight="1.1"
						>
							{AccessDataThree.title}
						</Text>
						<Text
							color="brand.dark100"
							fontWeight="400"
							fontSize="1.1rem"
							mt="2.5rem"
							mb="2rem"
							lineHeight="1.2"
						>
							{AccessDataThree.desc}
						</Text>

						<a href="https://app.snapcash.ng" target="_blank" rel="noreferrer">
							<SnapcashButton
								bgColor="transparent"
								color="brand.dark100"
								border="2px solid #BEC963"
								text={AccessDataThree.buttonText}
								src={ArrowRightBlack}
								p="1rem .7rem"
								ml="9rem"
								w=""
								alt="Arrow down"
							/>
						</a>
					</VStack>
				</Box>

				<Box mx={["auto", "auto", "auto", "auto", "0"]}>
					<Image
						src={AccessDataThree.backgroundImage}
						alt="Access Background Image Two"
						loading="lazy"
					/>
				</Box>
			</Stack>
		</Box>
	);
};

export const Airtime = () => {
	return (
		<Box p="0" bg="brand.white100" pt="4rem">
			<Stack
				maxW="1280px"
				mx="auto"
				overflow="hidden"
				direction={["column", "column", "column", "column", "row"]}
				alignItems="center"
				p={["2rem 1rem", "2rem 1rem", "2rem 3rem"]}
			>
				<Box
					w={["100%", "100%", "100%", "100%", "75%"]}
					order={["1", "1", "1", "1", "0"]}
				>
					<Image
						mx={["auto", "auto", "auto", "auto", "0"]}
						src={AccessDataFour.backgroundImage}
						alt="Access Background Image Four"
						loading="lazy"
					/>
				</Box>

				<Box
					pb={["1rem", "2rem", "2.5rem"]}
					w={["100%", "100%", "100%", "100%", "47%"]}
				>
					<VStack
						alignItems="flex-start"
						justifyContent="center"
						spacing="2.5rem"
						// mt={["4rem", "0", "0", "0"]}
					>
						<Text
							fontSize={["1.7rem", "2rem", "3.5rem", "4rem"]}
							fontWeight="700"
							lineHeight="1.1"
						>
							{AccessDataFour.title}
						</Text>
						<Text fontWeight="400" fontSize="1.16rem" lineHeight="1.2">
							{AccessDataFour.desc}
						</Text>

						<a href="https://app.snapcash.ng" target="_blank" rel="noreferrer">
							<SnapcashButton
								bgColor="transparent"
								color="brand.dark100"
								border="2px solid #BEC963"
								text={AccessDataFour.buttonText}
								src={ArrowRightWhite}
								p="1rem .7rem"
								ml="7.5rem"
								w=""
								alt="Arrow down"
							/>
						</a>
					</VStack>
				</Box>
			</Stack>
		</Box>
	);
};
