/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Flex, Image, Spinner, Stack, Text } from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { HeaderProps, ProductsInterface } from "@/types/interfaces";
import { Sidebar } from "@/components/sidebar/Sidebar";
import MenuIcon from "@/assets/svgs/menu-icon.svg";
import CloseIcon from "@/assets/images/close-icon.png";
import { AttributesContext, ShowModalContext } from "@/utils/contexts";

export const HeaderLayout = ({
	bg,
	src,
	color,
	arrowDown,
	arrowUp,
}: HeaderProps) => {
	const [activeProducts, setActiveProducts] = useState(JSON.parse(sessionStorage.getItem("products") as string));
	const { setAttribute } = useContext(AttributesContext);
	const { setShowModal } = useContext(ShowModalContext);

	const handleShowModal = (product_id: number | null) => {
		const product_key = "pk_live_DxhIehsdX7VB5bN7qcJBMUpEsNe90Qe8Lhi418XT";

		setShowModal(true);
		setAttribute({ product_key, product_id });
	};

	const [showSidebar, setShowSidebar] = useState(false);
	const [showLoan, setShowLoan] = useState(false);
	const myRef = useRef<HTMLDivElement>(null);

	const handleClick = () => setShowSidebar((toggle) => !toggle);

	const handleShowLoan = () => {
		setShowLoan((toggle) => !toggle);
	};

	useEffect(() => {
		const closeModal = (e: any) => {
			e.stopPropagation();
		};

		document.body.addEventListener("click", closeModal);
		return () => document.body.removeEventListener("click", closeModal);
	}, [showLoan]);

	useEffect(() => {
		const intervalId = setInterval(() => {
		  const productListString = sessionStorage.getItem("products");
		  if (productListString) {
			const productList = JSON.parse(productListString);
			setActiveProducts(productList);
		  }
		}, 3000);

		if (sessionStorage.getItem("products")) {
			clearInterval(intervalId);
		}
	  
		return () => {
			clearInterval(intervalId);
		};
	  }, []);

	return (
		<Box p="0" bg={bg} pos="sticky" top="0" w="100%" zIndex="3">
			<Stack
				onClick={(e) => e.stopPropagation()}
				maxW="1280px"
				mx="auto"
				direction={["row"]}
				justifyContent="space-between"
				alignItems="center"
				p={["1.5rem 1rem", "1.5rem 2rem", "1.5rem 3rem"]}
			>
				<Box>
					<Link to="/">
						<Image src={src}  h={["25px", "25px", "35px"]} alt="Header Logo" 	loading="lazy" />
					</Link>
				</Box>

				<Flex
					display={["none", "none", "none", "flex", "flex"]}
					alignItems="center"
					justifyContent="space-between"
					w={["37%", "60%", "60%", "47%", "37%"]}
					fontWeight="400"
				>
					<Box>
						<Flex ref={myRef} onClick={handleShowLoan} cursor="pointer">
							<Text fontSize="1rem" color={color}>
								Loans
							</Text>
							{!showLoan ? (
								<Image
									ml="0.8rem"
									mt="0.1rem"
									src={arrowDown}
									alt="Arrow Down White"
									loading="lazy"
								/>
							) : (
								<Image
									ml="0.8rem"
									mt="0.1rem"
									src={arrowUp}
									alt="Arrow Down White"
									loading="lazy"
								/>
							)}
						</Flex>

						{showLoan ? (
							<Box
								pos="absolute"
								top="4.5rem"
								boxShadow="0px 12px 32px rgba(0, 0, 0, 0.2)"
								w="150px"
								p="1rem"
								borderRadius="12px"
								bg="brand.white100"
								lineHeight="2"
								fontWeight="500"
								opacity="1"
								transition="all 2s cubic-bezier(0.075, 0.82, 0.165, 1)"
							>
								{activeProducts ? activeProducts.map((product: ProductsInterface) => (
									<Text
									    key={product.name}
										_hover={{
											color: "brand.purple100",
										}}
										cursor={"pointer"}
										onClick={() => handleShowModal(product.id)}
									>
									  {product.name}
									</Text>
								)) : <Spinner
								thickness='4px'
								speed='0.65s'
								emptyColor='gray.200'
								color='purple'
								size='sm'
								display={"block"}
								margin={"0 auto"}
							  />}
							</Box>
						) : (
							<Box
								pos="absolute"
								top="4.5rem"
								boxShadow="0px 12px 32px rgba(0, 0, 0, 0.2)"
								w="150px"
								p="1rem"
								borderRadius="12px"
								bg="brand.white100"
								lineHeight="2"
								fontWeight="500"
								pointerEvents="none"
								opacity="0"
								transition="all 1s cubic-bezier(0.075, 0.82, 0.165, 1)"
							>
									{activeProducts?.map((product: ProductsInterface) => (
									<Text
									    key={product.name}
										_hover={{
											color: "brand.purple100",
										}}
										cursor={"pointer"}
										onClick={() => handleShowModal(product.id)}
									>
									  {product.name}
									</Text>
								))}
							</Box>
						)}
					</Box>

					<a href="/#howItWorks">
						<Text fontSize="1rem" color={color}>
							How it works
						</Text>
					</a>

					<Link to="/faq">
						<Text fontSize="1rem" color={color}>
							FAQs
						</Text>
					</Link>

					<a href="https://app.snapcash.ng/signup" target="_blank" rel="noreferrer">
						<Button
							border="2px solid #BEC963;"
							borderRadius="8px"
							bgColor="transparent"
							color={color}
							fontSize="1rem"
							fontWeight="500"
							p="1.5rem 1rem"
							_hover={{
								bgColor: "none",
							}}
							_active={{
								bgColor: "none",
							}}
							_focus={{
								bgColor: "none",
							}}
						>
							Sign Up
						</Button>
					</a>
				</Flex>

				{!showSidebar ? (
					<Box
						onClick={handleClick}
						display={["block", "block", "block", "none", "none"]}
						color="brand.white100"
						fontSize="1.4rem"
						fontWeight="500"
						cursor="pointer"
					>
						<Image mr="1.2rem" src={MenuIcon} alt="Menu Icon" 	loading="lazy" />
					</Box>
				) : (
					<Box
						onClick={handleClick}
						display={["block", "block", "block", "none", "none"]}
						color="brand.white100"
						fontSize=".9rem"
						fontWeight="500"
						cursor="pointer"
					>
						<Image mr="1.2rem" src={CloseIcon} alt="Close Icon" 	loading="lazy" />
					</Box>
				)}
			</Stack>
			{showSidebar ? (
				<Sidebar
					myRef={myRef}
					showLoan={showLoan}
					handleShowLoan={handleShowLoan}
					setShowSidebar={setShowSidebar}
					arrowDown={arrowDown}
					arrowUp={arrowUp}
				/>
			) : null}
		</Box>
	);
};
