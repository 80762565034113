import { ChakraProvider, Box, Progress, Text } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import theme from "./themes/global";
import Routes from "./routes/Routes";
import "@/styles/index.css";
import { MagicAttributes } from "./components/loanModal/Modal";
import { AttributesContext, ShowModalContext } from "./utils/contexts";

export const App = () => {
	const [loading, setLoading] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [attribute, setAttribute] = useState<MagicAttributes>({
		product_key: null,
		product_id: null,
	});
	
	const attributeValue = useMemo(() => {
		return { attribute, setAttribute };
	  }, [attribute, setAttribute]);

	  const showModalValue = useMemo(() => {
		return { showModal, setShowModal };
	}, [showModal, setShowModal]);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 2000);
	}, []);

	return (
		<ChakraProvider theme={theme}>
		<AttributesContext.Provider value={attributeValue}>
        <ShowModalContext.Provider value={showModalValue}>
			<Box>
				<Box>
					{loading ? (
						<Box
							h="100vh"
							display="flex"
							justifyContent="center"
							alignItems="center"
							flexDir="column"
							>
							<Progress
								size="sm"
								bgColor="#E0E0E0"
								colorScheme="purple"
								borderRadius="12px"
								w={["50%", "50%", "40%", "20%"]}
								isIndeterminate
								/>
							<Text color="brand.gray600" fontWeight="600" mt=".8rem">
								Loading...
							</Text>
						</Box>
					) : (
						<Routes />
					)}
				</Box>
			</Box>
			</ShowModalContext.Provider>
		</AttributesContext.Provider>
		</ChakraProvider>
	);
};
