import { MerchantTermsPolicyDetails } from "@/utils/Legal";
import {
	Box,
	ListItem,
	OrderedList,
	UnorderedList,
	Text,
	Stack,
	LinkBox,
	Table,
	Thead,
	Tr,
	Td,
	Tbody,
} from "@chakra-ui/react";
import MainLayout from "@/layouts/MainLayout";
import { Fragment } from "react";

const MerchantTerms = () => {
	const mailTo = () => {
		return (
			<Text
				as="a"
				textDecoration="underline"
				cursor="pointer"
				onClick={() => (window.location.href = "mailto:hello@snapcash.ng")}
			>
				hello@snapcash.ng
			</Text>
		);
	};

	const mail = mailTo();

	const Tel = () => {
		return (
			<LinkBox textDecoration="underline" as="a" href="tel:+2342017004271">
				+234 201 700 4271
			</LinkBox>
		);
	};

	const callTel = Tel();

	return (
		<MainLayout>
			<Box p="0" bg="brand.purple100">
				<Stack
					maxW="1280px"
					mx="auto"
					p={["0rem 1rem 2rem", "2rem 2rem 2rem", "4rem 3rem 4rem"]}
				>
					<Text
						fontSize={["2rem", "2rem", "3rem"]}
						color="brand.white100"
						fontWeight="700"
					>
						SnapPurseTerms of Use
					</Text>
				</Stack>
			</Box>

			<Stack
				maxW="1280px"
				mx="auto"
				p={["0rem 1rem 0rem", "2rem 2rem 0rem", "6rem 3rem 4rem"]}
			>
				{/* <Text fontSize="1.5rem" fontWeight="700">
				PRIVACY POLICY
			</Text>
			<Text
				fontSize="1.2rem"
				fontWeight="600"
				mt="1rem"
				textDecoration="underline"
			>
				SnapPurse
			</Text> */}

				<Box w={["100%", "100%", "80%"]} order={["1", "1", "0"]} pt="1rem">
					{MerchantTermsPolicyDetails.map((item, idx: number | string) => {
						return (
							<Box key={idx}>
								<Text my="1rem" fontWeight="600">
									{item?.head}
								</Text>

								{item?.subhead && (
									<Text my="1rem" fontWeight="600">
										{item?.subhead}
									</Text>
								)}

								{item?.desc && (
									<Text
										fontWeight="400"
										dangerouslySetInnerHTML={{ __html: item?.desc }}
									/>
								)}

								{item?.showTable && (
									<Table width="100%" border="1px solid">
										<Thead>
											<Tr>
												<Td borderBottom="1px solid" borderRight="1px solid">
													Transaction Range{" "}
												</Td>
												<Td borderBottom="1px solid">Transaction Fee</Td>
											</Tr>
										</Thead>

										<Tbody>
											<Tr>
												<Td borderBottom="1px solid" borderRight="1px solid">
													Less than NGN 250
												</Td>
												<Td borderBottom="1px solid">NGN 0.50</Td>
											</Tr>
											<Tr>
												<Td borderBottom="1px solid" borderRight="1px solid">
													NGN 250 – NGN 999
												</Td>
												<Td borderBottom="1px solid">NGN 1.00</Td>
											</Tr>
											<Tr>
												<Td borderBottom="1px solid" borderRight="1px solid">
													NGN 1,000 – NGN 4,999
												</Td>
												<Td borderBottom="1px solid">NGN 5.00</Td>
											</Tr>
											<Tr>
												<Td borderBottom="1px solid" borderRight="1px solid">
													NGN 5,000 and above
												</Td>
												<Td borderBottom="1px solid">NGN 25.00</Td>
											</Tr>
										</Tbody>
									</Table>
								)}

								{item?.desc_one && (
									<Text
										fontWeight="400"
										dangerouslySetInnerHTML={{ __html: item?.desc_one }}
									/>
								)}

								{item?.list_one && (
									<UnorderedList>
										{item?.list_one.map(
											(
												item: {
													list_desc: string;
													inner_list?: { inner_list_desc?: string }[];
												},
												idx?: number | string
											) => {
												return (
													<Fragment key={idx}>
														<ListItem my="1rem">
															<Text as="span" fontWeight="400">
																{item?.list_desc}
															</Text>

															{item?.inner_list && (
																<OrderedList>
																	{item?.inner_list?.map(
																		(
																			item: { inner_list_desc?: string },
																			idx: number | string
																		) => {
																			return (
																				<Fragment key={idx}>
																					<ListItem my="1rem">
																						<Text as="span" fontWeight="400">
																							{item?.inner_list_desc}
																						</Text>
																					</ListItem>
																				</Fragment>
																			);
																		}
																	)}
																</OrderedList>
															)}
														</ListItem>
													</Fragment>
												);
											}
										)}
									</UnorderedList>
								)}

								{item?.desc_two && (
									<Text
										fontWeight="400"
										dangerouslySetInnerHTML={{ __html: item?.desc_two }}
									/>
								)}

								{item?.list_two && (
									<UnorderedList>
										{item?.list_two.map(
											(
												item: { list_heading?: string; list_desc?: string },
												idx: number | string
											) => {
												return (
													<Fragment key={idx}>
														<ListItem my="1rem" fontWeight="600">
															{item?.list_heading
																? `${item?.list_heading}: ${" "}`
																: ""}
															<Text as="span" fontWeight="400">
																{item.list_desc}
															</Text>
														</ListItem>
													</Fragment>
												);
											}
										)}
									</UnorderedList>
								)}

								{item?.footer && (
									<Text
										fontWeight="400"
										dangerouslySetInnerHTML={{ __html: item?.footer }}
									/>
								)}
							</Box>
						);
					})}
					<Box>
						<Text fontWeight="600" mt="1rem">
							Contact Us
						</Text>
						<Text>
							We will be pleased if you notify us of any inquiries or issues
							regarding these Terms, because this gives us the opportunity to
							seek a suitable solution. It also gives us the opportunity to
							improve the service/products offered to you and other customers.
							We also have an internal complaints procedure to effectively and
							promptly address any complaints. You may reach us by sending a
							mail to {mail} and or calling us on {callTel}.
						</Text>
					</Box>
				</Box>
			</Stack>
		</MainLayout>
	);
};

export default MerchantTerms;
