import { Box } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from  "react-router-dom";
import AboutUs from "@/pages/AboutUs";
import Faq from "@/pages/Faq";
import Home from "@/pages/Home";
import Legal from "@/pages/Legal";
import MerchantFaq from "@/pages/MerchantFaq";
import MerchantTerms from "@/pages/MerchantTerms";
import MerchantPrivacy from "@/pages/MerchantPrivacy";
import MagicFrame from "@/components/loanModal/Modal";
import { AttributesContext, ShowModalContext } from "@/utils/contexts";
import { useContext, useEffect, useRef } from "react";

const ConfigureRoutes = () => {
	const { attribute, setAttribute } = useContext(AttributesContext);
	const { showModal, setShowModal } = useContext(ShowModalContext);
	const modalRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (showModal) {
		  document.body.style.overflow ="hidden";
		} else {
		  document.body.style.overflowY = "scroll";
		}
	
		if (showModal && modalRef.current) {
		  modalRef.current.focus();
		}
	
		return () => {
		  document.body.style.overflowY = "scroll";
		};
	  }, [showModal]);
	
	const handleCloseModal = () => {
		setShowModal(false);
		setAttribute({ product_key: null, product_id: null });
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (handleCloseModal && event.key === "Escape") {
		  handleCloseModal();
		}
	  };
	
	return (
		<Box>
			<Router>
				<Routes>
					<Route path="/legal" element={<Legal />} />
					<Route path="/faq" element={<Faq />} />
					<Route path="/merchant-faqs" element={<MerchantFaq />} />
					<Route path="/merchant-terms" element={<MerchantTerms />} />
					<Route path="/merchant-privacy" element={<MerchantPrivacy />} />
					<Route path="/about-us" element={<AboutUs />} />
					<Route path="/" element={<Home />} />
				</Routes>
			</Router>

			{showModal && (
        <div
          ref={modalRef}
          onKeyDown={handleKeyDown}
          tabIndex={0}
          role="dialog"
          style={{
            width: "100%",
            height: "100vh",
            zIndex: 10,
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            transition: "0.3s",
		  }}
        >
          <MagicFrame
            product_key={attribute.product_key}
            product_id={attribute.product_id}
            close={() => handleCloseModal()}
          />
        </div>
      )}
		</Box>
	);
};

export default ConfigureRoutes;
