import { TermsDetails } from "@/utils/Legal";
import {
	Box,
	ListItem,
	UnorderedList,
	Text,
	List,
} from "@chakra-ui/react";
import { Fragment } from "react";

const Terms = () => {
	return (
		<Box>
			<Text fontSize="1.5rem" fontWeight="700">
				TERMS OF USE
			</Text>

			<Box>
				{TermsDetails.map((item, idx) => {
					return (
						<Box key={idx}>
							<Fragment>
								<List mt="1rem">
									{item.terms.map((item, idx) => {
										return (
											<Fragment key={idx}>
												<ListItem fontWeight="600" mt="1rem">
													{item.title}
												</ListItem>

												<Text
													fontWeight="400"
													mt="1rem"
													dangerouslySetInnerHTML={{ __html: item.desc }}
												/>

												<UnorderedList>
													{item.options.map((item, idx) => {
														return (
															<Fragment key={idx}>
																<ListItem fontWeight="600" mt="1rem">
																	{item.heading}
																</ListItem>
																<Text
																	mt="1rem"
																	dangerouslySetInnerHTML={{
																		__html: item.desc,
																	}}
																/>
															</Fragment>
														);
													})}
												</UnorderedList>
											</Fragment>
										);
									})}

									{item.termsTwo.map((item, idx) => {
										return (
											<Fragment key={idx}>
												<List mt="1rem">
													<ListItem fontWeight="600" mt="1rem">
														{item.title}
													</ListItem>

													<Text
														fontWeight="400"
														mt="1rem"
														dangerouslySetInnerHTML={{
															__html: item.desc,
														}}
													/>

													<UnorderedList>
														{item.options.map((item, idx) => {
															return (
																<Fragment key={idx}>
																	<ListItem mt="1rem">{item.list}</ListItem>
																</Fragment>
															);
														})}
													</UnorderedList>
												</List>
											</Fragment>
										);
									})}

									{item.termsThree.map((item, idx) => {
										return (
											<Fragment key={idx}>
												<ListItem fontWeight="600" mt="1rem">
													{item.title}
												</ListItem>

												<Text
													fontWeight="400"
													mt="1rem"
													dangerouslySetInnerHTML={{ __html: item.desc }}
												/>

												<UnorderedList>
													{item.options.map((item, idx) => {
														return (
															<Fragment key={idx}>
																<ListItem fontWeight="600" mt="1rem">
																	{item.heading}
																</ListItem>
																<Text
																	mt="1rem"
																	dangerouslySetInnerHTML={{
																		__html: item.desc,
																	}}
																/>
															</Fragment>
														);
													})}
												</UnorderedList>
											</Fragment>
										);
									})}

									{item.termsFour.map((item, idx) => {
										return (
											<Fragment key={idx}>
												<List mt="1rem">
													<ListItem fontWeight="600" mt="1rem">
														{item.title}
													</ListItem>

													<UnorderedList>
														{item.options.map((item, idx) => {
															return (
																<Fragment key={idx}>
																	<ListItem mt="1rem">{item.list}</ListItem>
																</Fragment>
															);
														})}
													</UnorderedList>
												</List>
											</Fragment>
										);
									})}

									{item.termsFive.map((item, idx) => {
										return (
											<Fragment key={idx}>
												<ListItem fontWeight="600" mt="1rem">
													{item.title}
												</ListItem>

												<UnorderedList>
													{item.options.map((item, idx) => {
														return (
															<Fragment key={idx}>
																<ListItem fontWeight="600" mt="1rem">
																	{item.heading}
																</ListItem>
																<Text
																	mt="1rem"
																	dangerouslySetInnerHTML={{
																		__html: item.desc,
																	}}
																/>
															</Fragment>
														);
													})}
												</UnorderedList>
											</Fragment>
										);
									})}
								</List>
							</Fragment>
						</Box>
					);
				})}
			</Box>
		</Box>
	);
};

export { Terms };
