import { Box, Image } from "@chakra-ui/react";
import HeroAppScreen from "@/assets/images/heroAppScreen.webp";
import Card from "@/assets/svgs/card.svg";
export const HeroAnimation = () => {
	return (
		<Box
			w="57%"
			pos="relative"
			display={["none", "none", "none", "none", "flex"]}
		>
			<Box role="group">
				<Image
					src={HeroAppScreen}
					pos="absolute"
					w="60%"
					bottom="-13.4rem"
					right="0"
					zIndex="1"
					transition="all .75s cubic-bezier(0.075, 0.82, 0.165, 1)"
					alt="HeroAppScreen"
					loading="lazy"
				/>

				<Image
					src={Card}
					pos="absolute"
					transform="scale(1.75)"
					top="12.7rem"
					zIndex="1"
					right="37.7%"
					filter="drop-shadow(0px 20px 80px rgba(0, 0, 0, 0.3))"
					transition="all .75s cubic-bezier(0.075, 0.82, 0.165, 1)"
					alt="Card"
					loading="lazy"
					_groupHover={{
						transform: "scale(.9)",
						filter: "none",
						top: "12.6rem",
						right: "31.6%",
					}}
				/>
			</Box>
		</Box>
	);
};
