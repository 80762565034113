export const PrivacyPolicyDetails = [
	{
		desc: "This Privacy Policy (“Policy”) has been put together to explain to you what we do with the personal information that you provide to us when you use SnapCash, a peer-to-peer lending solution (“Solution”, “platform” or “SnapCash”) developed by Sterling Bank (“we”, “us”, “our” and/or “ours”) incorporated under the laws of the Federal Republic of Nigeria with its registered office at Sterling Towers, 20 Marina, Lagos. <br />  <br /> The reference to ‘you’ or ‘your’, means you, any authorised person that use your account, anyone who initiates, conducts or conclude a transaction using your SnapCash account.	<br /> <br /> By continuing to visit, access or use the Platform either on the web or via the mobile app, you accept and consent to the provisions described in this Policy.",

		title: "The information we collect about you",
		subDesc:
			"We gather information from you for a number of reasons. We may need you to provide your Personal Information/Data such as your name, contact details, identification, work and residential addresses, gender, positions held, forms submitted, Bank Verification Number (BVN), payment details and other enquiry details which would enhance your service experience. Your Personal Data we collect, fall into various categories, such as:",
		options: [
			{
				subHeading: "Your Connections and Image information",
				subHeadingDesc:
					"We collect information from your address book or contact list, if you explicitly permit us to access such information. These include the phone number of persons who use our services and other contacts who do not use our service. Your contact list information is required for us to provide you with seamless value-added services through the Platform; for instance, airtime purchase for beneficiaries on your contact list. We may also access your gallery information for images or photos, where you wish to personalize your SnapCash profile.",
				options: [],
			},
			{
				subHeading:
					"Details about your banking activities and transactions with us.",
				subHeadingDesc:
					"This includes information on any bank accounts you use, debit card numbers, financial history, information you provide to deliver payment initiation services and account information services regarding accounts you hold with other providers.",
				options: [],
			},
			{
				subHeading: "Information on how you use your device/ Usage Data.",
				subHeadingDesc:
					"We may also collect information that your browser sends whenever you visit our website or banking applications or when you access the Solution or any of our services by or through a mobile device (“Usage Data”). This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit and the time spent on those pages amongst other diagnostic data. When you access the Service by or through a mobile device or use the mobile app, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic dat",
				options: [
					{
						innerHeading: "Sensitive data",
						innerHeadingDesc:
							"We may hold information about you which includes without limitation to health, criminal conviction information or biometric information used to uniquely identify you, (for example your fingerprint, facial recognition or voice recording). We will only hold this data when we need to for the purposes of the product or services we provide to you, where we are required to process the data for a public interest purpose, or where we have a legal obligation or your consent to do so",
						examples: [],
					},
					{
						innerHeading: "Tracking and Cookies Data",
						innerHeadingDesc:
							"We use cookies and similar tracking technologies to track the activity on our Service and hold certain information. Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyse our Service. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. You can also refuse permissions to read your phone data by the mobile application. However, if you do not accept cookies on your browser or allow permissions on your mobile device, our online service experience to you may be degraded and you may not be able to use some portions of our Service. We may also collect information about your internet browser settings and Internet Protocol (IP) address and other relevant information to help us identify your geographic location when providing you with our services.",
						examples: [
							{
								exHeading: "Session Cookies",
								exHeadingDesc:
									"We use Session Cookies to operate our Service. Session cookies will expire at the end of your browser session and allow us to link your actions during that browser session.",
							},
							{
								exHeading: "Preference  Cookies",
								exHeadingDesc:
									"We use Preference Cookies to remember your preferences and actions, across multiple sites. ",
							},
							{
								exHeading: "Security  Cookies",
								exHeadingDesc: "We use Security Cookies for security purposes.",
							},
							{
								exHeading:
									"Information from social networks or online accounts",
								exHeadingDesc:
									"This includes Information from any social media profiles or any accounts that you share with us. ",
							},
						],
					},
				],
			},
		],

		titleTwo: "How we use your personal Information",
		subDescTwo:
			"To the extent permissible under applicable law, we may use your information for the following legitimate actions:",
		optionsTwo: [
			{
				list: "Determine your eligibility for our other products and services.",
			},
			{
				list: "Verify your identity when you access your account information.",
			},
			{
				list: "Administer your accounts or other products and services that we or our partners/affiliates may provide to you.",
			},
			{
				list: "Respond to your requests and communicate with you.",
			},
			{
				list: "For understanding your financial needs.",
			},
			{
				list: "Prevention of crime, fraud, money laundering or terrorism financing activities.",
			},
			{
				list: "Managing our risks.",
			},
			{
				list: "Reviewing your credit or loan eligibility.",
			},
			{
				list: "For marketing the products and services of Sterling, related entities and affiliates. We may send you marketing and promotional messages by post, email, telephone, text, secure messaging, Mobile app. You may change your mind on how you wish to receive marketing messages from us or opt out of receiving such messages at anytime. However, we will continue to use your contact details to send you important information regarding your dealings with us.",
			},
			{
				list: "Process transactions, design products and profile customers.",
			},
			{
				list: "Notify you about changes to our Services.",
			},
			{
				list: "Allow you to participate in interactive features of our services when you choose to do so.",
			},
			{
				list: "Provide customer care and support and for internal operations, including troubleshooting, data analysis, testing, security, fraud-detection, and account management.",
			},
			{
				list: "Process your information for audit, statistical or research purposes in order to help us understand trends in our customer behaviour and to understand our risks better and curate products and services that are suitable to our customers’ needs.",
			},
			{
				list: "Monitor our conversation with you when we speak on the telephone (for example, to check your instructions to us, to analyze, to assess and improve customer service; for training and quality assurance purposes; for verification, fraud analysis and prevention purposes.",
			},
			{
				list: "Recover any debts that you may owe the Bank.",
			},
			{
				list: "Carry out analysis to evaluate and improve our business.",
			},
			{
				list: "Monitor the usage of our Services.",
			},
			{
				list: "Detect, prevent and address technical issues.",
			},
			{
				list: "Prevent fraud and enhance security of your account or our service platform.",
			},
			{
				list: "Comply with and enforcing applicable legal and regulatory requirements, relevant industry standards, contractual obligations and our policies.",
			},
			{
				list: "Provide you with tailored content and marketing messages such as recommending other products or services we believe you may be interested in.",
			},
			{
				list: "For other purposes required by law or regulation.",
			},
		],

		titleThree: "How do we share your information?",
		subDescThree:
			"We may share the information about you and your dealings with us, to the extent permitted by law, with the following:",
		optionsThree: [
			{
				list: "Sterling Branches and subsidiaries.",
			},
			{
				list: "Legal/Regulatory Authorities – It may be necessary by law, legal process, litigation, and/or requests from public and governmental authorities for FITC to disclose your personal information. We may also disclose information about you if we determine that for purposes of national security, law enforcement, or other issues of public importance, disclosure is necessary or appropriate.",
			},
			{
				list: "Professional Advisers: Auditors/Legal Advisers.",
			},
			{
				list: "Credit Agencies.",
			},
			{
				list: "Correspondent Banks.",
			},
			{
				list: "External Auditors.",
			},
			{
				list: "Strategic partners/service providers – for the purpose of providing our services to you. Your Personal information will not be shared with third parties for their marketing purposes.",
			},
			{
				list: "We have your consent to share the information.",
			},
			{
				list: "To comply with a legal obligation.",
			},
			{
				list: "To bring you improved service across our array of products and services, when permissible under relevant laws and regulations, by disclosing your personal information with Sterling Bank’s affiliated websites and businesses.",
			},
			{
				list: "To protect and defend the rights or property of the Bank.",
			},
			{
				list: "To prevent or investigate possible wrongdoing in connection with our Service.",
			},
			{
				list: "To protect the personal safety of users of our Service or the public.",
			},
			{
				list: "To protect against legal liability.",
			},
			{
				list: "in the event of a reorganization, merger, or sale we may transfer any and all personal information we collect to the relevant third party or.",
			},
			{
				list: "We find that your actions on our web sites or banking applications violate any part of our Privacy Policy.",
			},
		],

		titleFour: "How we secure your information",
		subDescFour:
			"We have implemented appropriate organizational and technical measures to keep your Personal Information/Data confidential and secure. This includes the use of encryption, access controls and other forms of security to ensure that your data is protected. We require all parties including our staff and third-parties processing data on our behalf to comply with relevant policies and guidelines. Where you have a password which grants you access to specific areas on our site or to any of our services, you are responsible for keeping this password confidential. We request that you do not to share your password or other authentication details (e.g., token generated codes) with anyone. <br /> <br /> Although we have taken measures to secure and keep your information confidential, because the security of your data is important to us, please be aware that no method of transmission over the Internet, or method of electronic storage can guarantee 100% security at all times. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security, you are responsible for securing and maintaining the privacy of your password and Account/profile registration information and verifying that the Personal Data we maintain about you is valid, accurate and up to date. If we receive instructions using your account login information, we will consider that you have authorised the instructions and process your instruction accordingly and without incurring any liability for doing so.",

		titleFive: "How long we keep your information.",
		subDescFive:
			"We retain your Information for as long as the purpose for which the information was collected continues. The information is then securely destroyed unless its retention is required to satisfy legal, regulatory, internal compliance or accounting requirements or to protect Sterling’s interest. <br /> <br />	Please note that regulations may require Sterling to retain your personal data for a specified period even after the end of your banking relationship with us.",
		optionsFive: [
			{
				subHeading: "Information from locations outside Nigeria",
				subHeadingDesc:
					"If you are located outside Nigeria and choose to provide information to us, please note that the data, including Personal Data, will be processed in Nigeria. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.",
				options: [],
			},
			{
				subHeading: "Accuracy and update of your information",
				subHeadingDesc:
					"You are responsible for making sure the information provided to the Bank is accurate and should inform the Bank on any changes as it occurs, this will enable us to update your information with us.<br /> <br /> Any changes will affect only future uses of your Personal Information. Subject to applicable law, which might, from time to time, oblige us to store your Personal Information for a certain period of time, we will respect your wishes to correct inaccurate information. Otherwise, we will hold your Personal Information for as long as we believe it will help us achieve our objectives as detailed in this Privacy Policy.",
				options: [],
			},
		],

		titleSix: "Your Rights",
		subDescSix:
			"You have certain rights in relation to the personal data we collect as provided by the Nigeria Data Protection Regulation 2019 (NDPR), these rights include:",
		optionsSix: [
			{
				list: "A right to access your personal data.",
			},
			{
				list: "A right to rectify/update your information in our possession.",
			},
			{
				list: "A right to request the erasure of personal data.",
			},
			{
				list: "A right to withdraw your consent to processing of personal data. This will however not affect the legality of processing carried out prior to any such withdrawal.",
			},
			{
				list: "Right to object to processing of personal data. This will only be applicable where there are no legal or operational reasons.",
			},
			{
				list: "Request that your personal data be made available to you in a common electronic format and/or request that such data be sent to a third party.",
			},
			{
				list: "Request that your information be erased. We might continue to retain such data if there are valid legal, regulatory or operational reasons.",
			},
		],

		titleSeven: [
			{
				title: "Privacy of minors",
				subDesc:
					"We do not knowingly collect names, email addresses, or any other personally identifiable information from children through the internet or on the Platform. We do not allow children under the age of 18 to open accounts nor provide online banking services for children less than 18 years of age without the consent of a guardian. If you are a parent or guardian and you are aware that your child has provided us with Personal Data without verification of parental consent, please promptly contact us.",
			},
			{
				title: "Third Party Websites",
				subDesc:
					"Our website, related websites and mobile applications may have links to or from other websites that are not operated by us. We have no control over and assume no responsibility for the security, privacy practices or content of third-party websites or services. We recommend that you always read the privacy and security statements on these websites.",
			},
			{
				title: "Service Providers",
				subDesc:
					"We may employ third party companies and individuals to facilitate our services on the SnapCash Platform (web or mobile) to provide the lending service on our behalf, to perform specific Service-related roles, or to assist us in analyzing how our Service is used. These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose outside of the service-specific need for which the data is required.",
			},

			{
				title: "Changes to this Policy",
				subDesc:
					"This Privacy Policy is effective as of the date stated above and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on our website. <br /> <br /> Based on the changing nature of privacy laws, user needs and our business, we may modify this Privacy Policy from time to time. Any change to our privacy policy will be communicated on our website, via email or by placing a notice on our Platform and this will be effective as soon as published. Accordingly, we encourage periodic reviews of this Privacy Policy for awareness of any changes that may have occurred. Your continued use of the Services after we post any modifications to the Privacy Policy on our website will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.",
			},
		],
	},
];

export const MerchantPrivacyPolicyDetails = [
	{
		desc: "This Privacy Policy (“Policy”) has been put together to explain to you what we do with the personal information that you provide to us when you use SnapPurse, the Quick Response payment solution developed by Sterling Bank Plc (“Platform” or “Solution”). <br />  <br /> Throughout this document, “we”, “us”, “our” “SnapPurse” and/or “ours” refer to Sterling Bank Plc (“Sterling” or “the Bank”) incorporated under the laws of the Federal Republic of Nigeria with its registered office at Sterling Towers, 20 Marina, Lagos. The reference to ‘you’ or ‘your’, means you, any authorised person that use your account, anyone who initiates, conducts or conclude a transaction using your SnapPurse account. <br /> <br /> By continuing to visit, access or use the Platform either on the web or via the mobile app, you accept and consent to the provisions described in this Policy.",

		title: "The information we collect about you",
		subDesc:
			"We gather information from you for a number of reasons. We may need you to provide your Personal Information/Data such as your name, contact details, identification, work and residential addresses, gender, positions held, forms submitted, Bank Verification Number (BVN), payment details and other enquiry details which would enhance your service experience on the platform. Your Personal Data we collect, fall into various categories, such as:",
		options: [
			{
				subHeading:
					"Details about your banking activities and transactions with us.",
				subHeadingDesc:
					"This includes information on any bank accounts you use, debit card numbers, financial history, information you provide to deliver payment initiation services and account information services regarding accounts you hold with other providers.",
				options: [],
				examples: [],
			},
			{
				subHeading: "Information on how you use your device/ Usage Data.",
				subHeadingDesc:
					"We may also collect information that your browser sends whenever you visit our website or banking applications or when you access the Platform or any of our services by or through a mobile device (“Usage Data”). This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Platform that you visit, the time and date of your visit and the time spent on those pages amongst other diagnostic data. When you access the Service by or through a mobile device or use the mobile app, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.",
				options: [],
				examples: [],
			},
			{
				subHeading: "Sensitive data",
				subHeadingDesc:
					"We may hold information about you which includes without limitation to health, criminal conviction information or biometric information used to uniquely identify you, (for example your fingerprint, facial recognition or voice recording). We will only hold this data when we need to for the purposes of the product or services we provide to you, where we are required to process the data for a public interest purpose, or where we have a legal obligation or your consent to do so.",
				options: [],
				examples: [],
			},
			{
				subHeading: "Information which you have consented to us using",
				subHeadingDesc:
					"We may collect information about your marketing preferences to help us share with you information about relevant services, products and offers that we think may be of interest to you.",
				options: [],
				examples: [],
			},
			{
				subHeading: "Tracking and Cookies Data",
				subHeadingDesc:
					"We use cookies and similar tracking technologies to track the activity on our Platform and hold certain information. Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyse our Service. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. You can also refuse permissions to read your phone data by the mobile application. However, if you do not accept cookies on your browser or allow permissions on your mobile device, our online service experience to you may be degraded and you may not be able to use some portions of our Service. We may also collect information about your internet browser settings and Internet Protocol (IP) address and other relevant information to help us identify your geographic location when providing you with our services.",
				examples: [
					{
						exHeading: "Session Cookies",
						exHeadingDesc:
							"We use Session Cookies to operate our Service. Session cookies will expire at the end of your browser session and allow us to link your actions during that browser session.",
					},
					{
						exHeading: "Preference  Cookies",
						exHeadingDesc:
							"We use Preference Cookies to remember your preferences and actions, across multiple sites. ",
					},
					{
						exHeading: "Security  Cookies",
						exHeadingDesc: "We use Security Cookies for security purposes.",
					},
				],
				options: [],
			},
			{
				subHeading: "Information from social networks or online accounts",
				subHeadingDesc:
					"This includes Information from any social media profiles or any accounts that you share with us. ",
				options: [],
				examples: [],
			},
			{
				subHeading: "Other Information",
				subHeadingDesc:
					"We may also process specific categories of information for specific and limited purposes, such as detecting and preventing financial crime or making our services available to customers. We will only treat particular types of information where we have obtained your explicit consent or are otherwise lawfully permitted to do so.",
				options: [],
				examples: [],
			},
		],

		titleTwo: "How we use your personal Information",
		subDescTwo:
			"To the extent permissible under applicable law, we may use your information for the following legitimate actions:",
		optionsTwo: [
			{
				list: "Determine your eligibility for our other products and services.",
			},
			{
				list: "Verify your identity when you access your account information.",
			},
			{
				list: "Administer your accounts or other products and services that we or our partners/affiliates may provide to you.",
			},
			{
				list: "Respond to your requests and communicate with you.",
			},
			{
				list: "For understanding your financial needs.",
			},
			{
				list: "Prevention of crime, fraud, money laundering or terrorism financing activities.",
			},
			{
				list: "Managing our risks.",
			},
			{
				list: "For marketing the products and services of Sterling, related entities and affiliates. We may send you marketing and promotional messages by post, email, telephone, text, secure messaging, Mobile app. You may change your mind on how you wish to receive marketing messages from us or opt out of receiving such messages at anytime. However, we will continue to use your contact details to send you important information regarding your dealings with us",
			},
			{
				list: "Process transactions, design products and profile customers.",
			},
			{
				list: "Notify you about changes to our Services.",
			},
			{
				list: "Allow you to participate in interactive features of our services when you choose to do so.",
			},
			{
				list: "Provide customer care and support and for internal operations, including troubleshooting, data analysis, testing, security, fraud-detection, and account management.",
			},
			{
				list: "Process your information for audit, statistical or research purposes in order to help us understand trends in our customer behaviour and to understand our risks better and curate products and services that are suitable to our customers’ needs.",
			},
			{
				list: "Monitor our conversation with you when we speak on the telephone (for example, to check your instructions to us, to analyze, to assess and improve customer service; for training and quality assurance purposes; for verification, fraud analysis and prevention purposes.",
			},
			{
				list: "Carry out analysis to evaluate and improve our business.",
			},
			{
				list: "Monitor the usage of our Services.",
			},
			{
				list: "Detect, prevent and address technical issues.",
			},
			{
				list: "Prevent fraud and enhance security of your account or our service platform.",
			},
			{
				list: "Comply with and enforcing applicable legal and regulatory requirements, relevant industry standards, contractual obligations and our policies.",
			},
			{
				list: "Provide you with tailored content and marketing messages such as recommending other products or services we believe you may be interested in.",
			},
			{
				list: "For other purposes required by law or regulation.",
			},
		],

		titleThree: "How do we share your information?",
		subDescThree:
			"We may share the information about you and your dealings with us, to the extent permitted by law, with the following:",
		optionsThree: [
			{
				list: "Sterling Bank’s related, affiliate or subsidiary Companies",
			},
			{
				list: "Legal/Regulatory Authorities – It may be necessary by law, legal process, litigation, and/or requests from public and governmental authorities for FITC to disclose your personal information. We may also disclose information about you if we determine that for purposes of national security, law enforcement, or other issues of public importance, disclosure is necessary or appropriate",
			},
			{
				list: "Professional Advisers: Auditors/Legal Advisers.",
			},
			{
				list: "Correspondent Banks.",
			},
			{
				list: "External Auditors.",
			},
			{
				list: "Strategic partners/service providers – for the purpose of providing our services to you. Your Personal information will not be shared with third parties for their marketing purposes.",
			},
			{
				list: "We have your consent to share the information.",
			},
			{
				list: "To comply with a legal obligation.",
			},
			{
				list: "To bring you improved service across our array of products and services, when permissible under relevant laws and regulations, by disclosing your personal information with Sterling Bank’s affiliated websites and businesses.",
			},
			{
				list: "To protect and defend the rights or property of Sterling Bank.",
			},
			{
				list: "To prevent or investigate possible wrongdoing in connection with our Service.",
			},
			{
				list: "To protect the personal safety of users of our Service or the public.",
			},
			{
				list: "To protect against legal liability.",
			},
			{
				list: "In the event of a reorganization, merger, or sale we may transfer any and all personal information we collect to the relevant third party or.",
			},
			{
				list: "We find that your actions on our web sites or banking applications violate any part of our Privacy Policy.",
			},
			{
				list: "We may provide information to trusted third parties who assist us in conducting our business. We require that these parties agree to process such information based on our instructions and in compliance with this privacy policy and any other appropriate confidentiality and security measures.",
			},
		],

		titleFour: "How we secure your Information",
		subDescFour:
			"We have implemented appropriate organizational and technical measures to keep your Personal Information/Data confidential and secure. This includes the use of encryption, access controls and other forms of security to ensure that your data is protected. We require all parties including our staff and third-parties processing data on our behalf to comply with relevant policies and guidelines. Where you have a password which grants you access to specific areas on our site or to any of our services, you are responsible for keeping this password confidential. We request that you do not to share your password or other authentication details (e.g., token generated codes) with anyone. <br /> <br /> Although we have taken measures to secure and keep your information confidential, because the security of your data is important to us, please be aware that no method of transmission over the Internet, or method of electronic storage can guarantee 100% security at all times. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security, you are responsible for securing and maintaining the privacy of your password and Account/profile registration information and verifying that the Personal Data we maintain about you is valid, accurate and up to date. If we receive instructions using your account login information, we will consider that you have authorised the instructions and process your instruction accordingly and without incurring any liability for doing so.",

		titleFive: "How long we keep your information.",
		subDescFive:
			"We retain your Information for as long as the purpose for which the information was collected continues. The information is then securely destroyed unless its retention is required to satisfy legal, regulatory, internal compliance or accounting requirements or to protect our interest. <br /> <br />	Please note that regulations may require Sterling to retain your personal data for a specified period even after the end of your banking relationship with us.",
		optionsFive: [
			{
				subHeading: "Information from locations outside Nigeria",
				subHeadingDesc:
					"If you are located outside Nigeria and choose to provide information to us, please note that the data, including Personal Data, will be processed in Nigeria. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.",
				options: [],
			},
			{
				subHeading: "Accuracy and update of your Information",
				subHeadingDesc:
					"You are responsible for making sure the information provided to us is accurate and should inform us on any changes as it occurs, this will enable us to update your information with us.<br /> <br /> Any changes will affect only future uses of your Personal Information. Subject to applicable law, which might, from time to time, oblige us to store your Personal Information for a certain period of time, we will respect your wishes to correct inaccurate information. Otherwise, we will hold your Personal Information for as long as we believe it will help us achieve our objectives as detailed in this Privacy Policy.",
				options: [],
			},
		],

		titleSix: "Your Rights",
		subDescSix:
			"You have certain rights in relation to the personal data we collect as provided by the Nigeria Data Protection Regulation 2019 (NDPR), these rights include:",
		optionsSix: [
			{
				list: "A right to access your personal data.",
			},
			{
				list: "A right to rectify/update your information in our possession.",
			},
			{
				list: "A right to request the erasure of personal data.",
			},
			{
				list: "A right to withdraw your consent to processing of personal data. This will however not affect the legality of processing carried out prior to any such withdrawal.",
			},
			{
				list: "Right to object to processing of personal data. This will only be applicable where there are no legal or operational reasons authorizing us to process your data.",
			},
			{
				list: "Request that your personal data be made available to you in a common electronic format and/or request that such data be sent to a third party.",
			},
			{
				list: "Request that your information be erased. We might continue to retain such data if there are valid legal, regulatory or operational reasons.",
			},
		],

		titleSeven: [
			{
				title: "Privacy of minors",
				subDesc:
					"We do not knowingly collect names, email addresses, or any other personally identifiable information from children through the internet or on the Platform. We do not allow children under the age of 18 to open accounts nor provide services for children less than 18 years of age without the consent of a guardian. If you are a parent or guardian and you are aware that your child has provided us with Personal Data without verification of parental consent, please promptly contact us.",
			},
			{
				title: "Third Party Websites",
				subDesc:
					"Our website, related websites and mobile applications may have links to or from other websites that are not operated by us. We have no control over and assume no responsibility for the security, privacy practices or content of third-party websites or services. We recommend that you always read the privacy and security statements on these websites.",
			},
			{
				title: "Service Providers",
				subDesc:
					"We may employ third party companies and individuals to facilitate our services on the SnapPurse Platform (web or mobile) to provide the service on our behalf, to perform specific Service-related roles, or to assist us in analyzing how our Service is used. These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose outside of the service-specific need for which the data is required.",
			},

			{
				title: "Changes to this Policy",
				subDesc:
					"This Privacy Policy is effective as of the date stated above and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on our website. <br /> <br />  Based on the changing nature of privacy laws, user needs and our business, we may modify this Privacy Policy from time to time. Any change to our privacy policy will be communicated on our website, via email or by placing a notice on our Platform and this will be effective as soon as published. Accordingly, we encourage periodic reviews of this Privacy Policy for awareness of any changes that may have occurred. Your continued use of the Services after we post any modifications to the Privacy Policy on our website will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.",
			},
		],

		titleEight: "CLOSING AND DELETING YOUR ACCOUNT",
		optionsEight: [
			{
				subHeading: "Deactivating or Closing Your SnapPurse Account",
				subHeadingDesc:
					"You can deactivate or close your SnapPurse account at any time. If you wish to do so, please visit the profile section on the app and initiate deletion or you can contact us using the information provided in the 'How to Contact Us' section. Additionally, if you decide to stop using the Platform, please ensure you delete the SnapPurse app from your digital smart device.",
				options: [],
			},
			{
				subHeading: "Data Deletion",
				subHeadingDesc:
					"Upon request for account closure, you may also request the deletion of your data. This includes personal information and any data associated with your account. Please note that while we will make every effort to delete your data promptly, some information may be retained to comply with legal obligations, resolve disputes, or enforce our agreements.",
				options: [],
			},
			{
				subHeading: "Account Deletion",
				subHeadingDesc:
					"Although users can request the deletion of their data, it is important to understand that this action may not delete the actual account created with SnapPurse. The account information may be retained as required by the financial institution, Sterling Bank, for regulatory and compliance purposes. If you have any concerns about this, please contact us for further assistance.",
				options: [],
			},
		],
	},
];

export const MerchantTermsPolicyDetails = [
	{
		head: "WELCOME TO SNAPPURSE",
		desc: "SnapPurse (“Platform”/ “App”) is a Quick Response (QR) payment solution developed by Sterling Bank Plc. (“Us, We, Our”). <br /><br /> The following Terms and Conditions (“Terms”) govern your access to and use of the Platform. Please read these terms carefully as access to, and use of SnapPurse’s services (“Services”), and Platform is conditional on your agreement to these Terms. You must read, agree with, and accept all the terms and conditions contained in these Terms. By signing up, or by using the Platform, you are bound to these Terms, and you indicate your continued acceptance of these Terms.",
	},
	{
		head: "ABOUT SNAPPURSE",
		desc: "SnapPurse is a Quick Response (QR) payment solution that facilitates the receipt of payments through a QR code. The Platform is an easy to use, quick to launch payment receipt Platform that uses a static QR code to collect payment and can be used offline without internet connection. The Platform facilitates receipt of funds/payments from any Nigerian Quick Response (NQR) enabled bank App. You can use the Platform for the following services available as at the date of this document:",
		list_one: [
			{
				list_desc: "Receipt of payment/funds",
			},
			{
				list_desc:
					"Transfer of the received payment to any Bank account profiled on the Platform.",
			},
		],
		desc_two: "The benefits of the Platform include but is not limited to:",
		list_two: [
			{
				list_heading: "Fast transactions",
				list_desc:
					"The SnapPurse app opens within seconds and payments are collected instantly by scanning the code or making a transfer to the account number displayed.",
			},
			{
				list_heading: "Setting up is easy",
				list_desc:
					"Just download the app from Google Play Store or the App Store and set up your merchant profile within seconds and at zero cost.",
			},
			{
				list_heading: "It is safe and secure",
				list_desc:
					"The SnapPurse app is designed with the latest technology; it is safe and secure. The payments go straight to your merchant account, so you can rest easy and enjoy peace of mind.",
			},
			{
				list_heading: "You can use it offline",
				list_desc:
					"The SnapPurse app will still open and display your QR code even when there is no internet connection.",
			},
			{
				list_heading: "Transaction management is seamless",
				list_desc:
					"On the SnapPurse app, you can access your sales and transaction history as well as your account balance. You can also generate a receipt and share with your customers as proof of payment.",
			},
		],
	},
	{
		head: "ACCESS TO THE SERVICE AND USE OF THE PLATFORM",
		desc: "To be eligible to use the Platform you must;",
		list_one: [
			{
				list_desc:
					"Be 18 years or above. If you are under 18 and you wish to access or use the Services, your parents or legal guardian must consent to our Terms, otherwise, you shall immediately discontinue its use. We also reserve the right to discontinue your use of the Platform if you are less than 18 years and have not gotten the consent of your parents or legal guardian.",
			},
			{
				list_desc: "Own a Nigerian bank account with any bank in Nigeria.",
			},
			{
				list_desc:
					"Have access to a digital smart device e.g., an Android or Apple phone, iPad etc.",
			},
		],
	},
	{
		head: "ACCOUNT ENROLLMENT",
		desc: "To access the Services on the Platform, first, you need to download the App from the google or Apple play store, then sign up on the App. You will need to sign up on the App with your google or Apple account then input your preferred username and create a password. Once you sign up on the App, you will be required to provide certain information (User Information) and follow the following steps to enable us validate your identity;",
		list_one: [
			{
				list_desc: "Input your registered Nigerian phone number.",
			},
			{
				list_desc:
					"Input a one-time password (OTP) sent to the phone number provided.",
			},
			{
				list_desc: "Select your Bank and input your bank account details.",
			},
			{
				list_desc: "Take a picture!",
			},
			{
				list_desc:
					"Upon completion of the above steps a Sterling merchant account is created for you",
			},
		],
		desc_two: "In exchange for your use of the Services. You agree to:",
		list_two: [
			{
				list_desc:
					"Provide true, accurate, current and complete information about yourself.",
			},
			{
				list_desc:
					"Each time you log on, maintain and promptly update such User Information to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or we have reasonable grounds to suspect that such Account information is untrue, inaccurate, not current or incomplete, we reserve the right to refuse any future request(s) to use the Services.",
			},
		],
	},
	{
		head: "PAYMENT PROCESSING TERMS",
		subhead: "How to initiate a payment on the Platform",
		desc: "The following steps details how a payment can be initiated and received by you on the Platform:",
		list_one: [
			{
				list_desc: "Tap on the SnapPurse App to open the Platform.",
			},
			{
				list_desc:
					"By default, the app would open to the QR code screen showing a static QR code and the Sterling Bank account for receipt of payment at the bottom of the Platform.",
			},
			{
				list_desc:
					"A person initiating payment to you will scan the code generated by you on the Platform by using the NQR payment option on their NQR enabled Bank app.",
			},
			{
				list_desc:
					"You will receive a pop-up notification on the App letting you know if the transaction was successful or not, followed by an email or SMS notification of a credit alert if the transaction was successful.",
			},
			{
				list_desc:
					"Once payment is completed and received by you into your Sterling merchant account, you may initiate a funds transfer of the funds received to any bank account profiled on the Platform.",
			},
			{
				list_desc:
					"Where the person initiating payment to you does not have a NQR enabled bank app, they can simply make a transfer to the account number displayed on the Platform and you will receive the funds within a few seconds.",
			},
		],
	},
	{
		head: "Applicable Fees and transaction charges",
		desc: "We do not charge for the use of the Platform; however, your mobile network provider may charge you to access the App. You are responsible for the charges and fees charged by your mobile network provider to access and operate the App. <br /><br /> We may charge you a fee for payment transactions consummated on the App and for funds credit and debit notifications sent to your phone number via text messages facilitated by a mobile network provider. <br /><br /> The following charges/ fees apply to transactions initiated on the Platform, these charges should serve as a guide to you as to the charges that we may deduct for transactions initiated on the Platform, we may at our sole discretion amend these charges in line with regulatory policies, industry standards and market realities. <br /><br /> The applicable fees and charges are as follows:",
		showTable: true,
		desc_one:
			"The transaction fees stated above are exclusive of VAT which will be paid by anyone initiating a payment to you via the Platform.<br /><br /> Upon your account enrollment, you give us permission to deduct any fees/charges listed above or we communicate or notify you, on any transaction done on the Platform. If these fees change, you will be notified before initiating any transaction and given the option to discontinue the transaction.",
	},
	{
		head: "Disputed or Failed Transactions",
		desc: "Resolution of all disputed or failed transactions will be handled by our customer success team. To initiate a transaction resolution process for a failed or disputed transaction, you can send us a message via the chatbot on the app, call us on 017004271 or send an email to hello@SnapPurse.ng and we will promptly handle your request and give you a feedback.",
	},
	{
		head: "Account Documentation and Transaction Limit",
		desc: "At onboarding on SnapPurse, a financial inclusion 1 account (Kia Kia account), designed for individuals with no documentation is opened for you. The account will have a daily single spend limit of N50,000 and daily deposit of N300,000 and will go into restriction thereafter, until the account is upgraded for you, when you the provide additional KYC documentation to be notified to you.",
	},
	{
		head: "REPRESENTATIONS AND WARRANTIES",
		desc: "You represent and warrant that:",
		list_one: [
			{
				list_desc:
					"You have full power, capacity, and authority to access the Platform/use the Services;",
			},
			{
				list_desc:
					"The terms hereof do not violate any obligation by which you are bound, whether arising by contract or operation of law, or otherwise; and",
			},
			{
				list_desc:
					"You shall promptly notify us if any of your representations or warranties in this Agreement are no longer true or completely accurate.",
			},
		],
	},
	{
		head: "USER’S RESPONSIBILITIES",
		desc: "We have compiled a list of things you need to do to help us keep your SnapPurse account safe:",
		list_one: [
			{
				list_desc:
					"It is your sole responsibility to keep your SnapPurse account safe: Security Details, PIN and any part of your account security should not be shared with anybody else or carelessly stored or disclosed under any circumstance.",
			},
			{
				list_desc:
					"If you leave your Electronic Access Device without logging out, write your PIN down in a public space or share your details with anybody, we will not be liable for any errors, or results that may come of it.",
			},
			{
				list_desc:
					"We will always use all reasonable efforts to keep the App and service safe, and your security settings will help us narrow it further down. However, if these issues arise due to carelessness with any part of your account security, there is very little we can do, and we will be relieved of any liability as regards the occurrence of a breach",
			},
			{
				list_desc:
					"If you believe or reasonably suspect that there is a fraudulent act, we haven’t caught it is your duty to notify us immediately. We will then investigate and report back to you through the most secure channel you provide to us.",
			},
			{
				list_desc:
					"You are required to change your security options if you believe any part of your security has been compromised.",
			},
			{
				list_desc:
					"Where you notify us of your intention to change your password, PIN or security options, we will, with your approval, delete these details and allow you set up new ones.",
			},
			{
				list_desc:
					"After initial sign up, we will not contact you (or ask anyone to do so on our behalf) with a request to disclose your log in details or transaction password/PIN. If you receive any such request from anyone (even if they are using our name and logo and appear to be genuine) then it is likely to be fraudulent and you must not provide your log in details to them under any circumstances. Also, you should report any such request to us immediately.",
			},
		],
	},
	{
		head: "PROHIBITED USE",
		desc: "Below are examples of activities prohibited on the Platform. This list is not exhaustive, and we reserve the right to restrict access to and/or investigate any User who, in our sole discretion, violates any of the terms listed below: <br /><br /> In view of the foregoing, you understand and agree:",
		list_one: [
			{
				list_desc:
					"Not to use your account in any manner that is misleading, fraudulent, or otherwise harmful to us, our service providers, and other customers.",
			},
			{
				list_desc:
					"Not to violate any law, regulation, industry requirement, or third-party guidelines or agreements related to the Platform by which you are bound.",
			},
			{
				list_desc:
					"Not to provide information or carry out transactions that are fraudulent, misleading, inaccurate, or dishonest.",
			},
			{
				list_desc:
					"Not to fund a ransom, human trafficking or exploitation, vigilantism, bribes or bounty.",
			},
			{
				list_desc:
					"Not to use, or misuse, the Platform in any way which may impair its functionality.",
			},
			{
				list_desc:
					"Not to use another person’s email address, identity or contact detail.",
			},
			{
				list_desc:
					"Not to copy, modify, translate, or otherwise create derivative works from any part of, or reverse engineer any part of the Platform (or attempt to do so, or assist anyone else to do so).",
			},
			{
				list_desc:
					"Not to sell, transfer, or assign any rights you have in relation to the Platform – this includes sharing your access or information from the Platform with any other person you are solely responsible for all activities that occur using your password and login whether or not you authorize the activity.",
			},
		],
	},
	{
		head: "INTELLECTUAL PROPERTY",
		desc: "You agree that all intellectual property rights and database rights, whether registered or unregistered, in the Platform, information content on the Platform and all the website designs, including, but not limited to, text, graphics, software, photos, video, music, sound, and their selection and arrangement, and all software compilations, underlying source code and software shall remain at all times vested in us or our licensors. Use of such material will only be permitted as expressly authorized by us or our licensors.<br /><br /> Any unauthorized use of the material and content of this Platform is strictly prohibited, and you agree not to, or facilitate any third party to, copy, reproduce, transmit, publish, display, distribute, commercially exploit or create derivative works of such material and content.",
	},
	{
		head: "DATA PROTECTION",
		desc: "For information on how we process and protect your personal data, please refer to our <a target='_blank' href='/merchant-privacy' class='chakra-linkbox css-1nr5wwq'>Privacy Policy</a>",
	},
	{
		head: "DISCLAIMERS",
		list_one: [
			{
				list_desc:
					"The Platform may be under constant upgrades, and some functions and features may not be fully operational during periods of upgrade or software maintenance.",
			},
			{
				list_desc:
					"We disclaim any liability arising due to the vagaries that can occur in the electronic distribution of information.",
			},

			{
				list_desc:
					"You acknowledge that third party services may be available on the Platform. We may have formed partnerships or alliances with some of these third parties from time to time in order to facilitate the provision of certain services to you. However, you acknowledge and agree that at no time are we making any representation or warranty regarding any third party's services, nor will we be liable to you or any third party for any consequences or claims arising from or in connection with such third party including, and not limited to, any liability or responsibility for, death, injury or impairment experienced by you or any third party. You hereby disclaim and waive any rights and claims you may have against us with respect to a third party’s services.",
			},
			{
				list_desc:
					"We do not, in any way, endorse any information or service offered or described on the Platform. In no event shall we be liable to you or any third party for any decision made, or action taken in reliance on such information.",
			},
			{
				list_desc:
					"You assume all responsibility and risk with respect to your use of the Platform. The services are provided and available “as is,” and “as available”. You understand and agree that, to the fullest extent permitted by law, we disclaim all warranties, representations and endorsements, express or implied, with regard to the Platform, including, without limitation, implied warranties of title, merchantability, non-infringement and fitness for a particular purpose.",
			},
			{
				list_desc:
					"We do not warrant use of the Platform will be uninterrupted or error-free or that errors will be detected or corrected. We do not assume any liability or responsibility for any computer viruses, bugs, malicious code or other harmful components, delays, inaccuracies, errors or omissions, or the accuracy, completeness, reliability or usefulness of the information disclosed or accessed through the services.",
			},
			{
				list_desc:
					"In no event, under no legal or equitable theory (whether tort, contract, strict liability or otherwise), shall we or any of our respective employees, directors, officers, agents or affiliates, be liable hereunder or otherwise for any loss or damage of any kind, direct or indirect, in connection with or arising from the use of the Platform/ services or our agreement with you concerning the services, including, but not limited to,",
				inner_list: [
					{
						inner_list_desc:
							"The use of or inability to use the Platform, the service, or the content,",
					},
					{
						inner_list_desc:
							"Any transaction conducted through or facilitated by the Platform.",
					},
					{
						inner_list_desc:
							"Any claim attributable to errors, omissions, or other inaccuracies in the Platform, the service and/or the content,",
					},
					{
						inner_list_desc:
							"Unauthorized access to or alteration of your transmissions or data, or",
					},
					{
						inner_list_desc:
							"Any other matter relating to the Platform or the service and any compensatory, direct, consequential, incidental, indirect, special or punitive damages, lost anticipated profits, loss of goodwill, loss of data, business interruption, accuracy of results, or computer failure or malfunction, even if we have been advised of or should have known of the possibility of such damages.",
					},
				],
			},
		],
	},
	{
		head: "AVAILABILITY OF SERVICES",
		list_one: [
			{
				list_desc:
					"Whilst we use our best endeavor to ensure the Platform and Services are available optimally, we make no warranty that any part of the Platform will operate uninterrupted or error free and we accept no liability for loss or damage caused from any interruption or error on any part of the Platform.",
			},
			{
				list_desc:
					"We do not guarantee secure, continuous, uninterrupted access to any part of the services, software, or systems, including any networks and servers used to provide any of the services stated herein. Accordingly, we are not responsible for the matters, which include actions of hackers and other unauthorized third parties that breach our reasonable security procedure. No method of electronic transmission or storage is 100% secure, therefore, we cannot guarantee absolute security of the Platform.",
			},
			{
				list_desc:
					"We will also not be liable for any failure to provide Services, in part or full, due to abnormal or unforeseen circumstances beyond our control, the consequences of which would have been unavoidable despite all efforts to the contrary. This includes but is not limited to network failures, or in the case of mobile networks, when you are in not in an area of mobile coverage.",
			},
			{
				list_desc:
					"We reserve the right to remove a service from the Platform with no obligation to give advance notice, and we shall not be liable for losses, costs or expenses arising from any such refusal or removal. You assume the risks associated with the use of the Platform.",
			},
		],
	},
	{
		head: "DISCONTINUATION OF SERVICES OR YOUR ACCOUNT",
		desc: "We reserve the right to suspend your usage of the Platform or any of the Services immediately and without advance notice;",
		list_one: [
			{
				list_desc: "In order to maintain security",
			},
			{
				list_desc: "You have breached the terms and conditions.",
			},
			{
				list_desc: "You give us false information at any time.",
			},
			{
				list_desc: "We suspect fraud or an attempt at fraud.",
			},
			{
				list_desc: "If there is suspicious activity on your account",
			},
			{
				list_desc:
					"You have not satisfied any anti-money laundering and combating the financing of terrorism requirements.",
			},
			{
				list_desc: "You have broken the law or attempt to break the law.",
			},
			{
				list_desc:
					"We receive notice of your mental incapacity, bankruptcy or death.",
			},
			{
				list_desc:
					"There are system maintenance issues which need to be addressed promptly.",
			},
			{
				list_desc: "New upgrades are being introduced to the Platform.",
			},
			{
				list_desc: "We have legal obligations to do so.",
			},
		],
		footer:
			"We will attempt to notify you, except it would compromise our security measures, or it is unlawful to do so, or impracticable within the circumstances. Suspension or discontinuation of your right to use the Service or Platform, shall not affect any outstanding obligation you owe us.",
	},
	{
		head: "MODIFICATIONS TO THE TERMS",
		list_one: [
			{
				list_desc:
					"We may change these terms from time to time. This is solely at our discretion and changes will be effective when posted on the Platform with no other notice provided. Please check the Terms regularly for updates as changes shall be effective immediately, and we may indicate the date of such revision.",
			},
			{
				list_desc:
					"In addition, we may change, suspend or discontinue any aspect of the Services at any time. We may also impose limits on certain services or restrict your access to parts or all of the Platform or the Services provided without notice or liability.",
			},
			{
				list_desc:
					"You are free to decide whether or not to accept a revised version of these Terms, but accepting these Terms, as revised, is required for you to continue accessing or using the Platform and Services. If you do not agree to these Terms or any revised version of these Terms, your sole recourse is to terminate your access or use of the Services. Except as otherwise expressly stated by us, your access and use of the Services are subject to, and constitute your acceptance of, the version of these Terms in effect at the time of your access or use.",
			},
		],
	},
	{
		head: "ADDITIONAL TERMS",
		subhead: "Acceptance of Electronic Documents",
		desc: "You agree that all agreements, notices, disclosures, records, and other communications for all services provided to you under these Terms and in connection with your relationship with us (collectively, Communications) that we provide to you electronically satisfy any legal requirement that such communications be in writing.",
	},
	{
		head: "Feedback Release",
		desc: "We may from time to time reach out to Clients/Users to provide feedback/testimonials in respect of our Services and/or the Platform. This would usually involve the use of your images, biographical information, recordings, video/audio clips (the “Material”). You acknowledge that the Material may be used in diverse settings within an unrestricted geographic area. You hereby release Sterling Bank Plc, its representatives, employees, managers, members, officers, parent companies, subsidiaries, and directors, from all claims and demands arising out of or in connection with any use of the Material, including, without limitation, all claims for invasion of privacy, infringement of your right of publicity, defamation and any other personal and/or property rights. Additionally, you waive any right to royalties or other compensation arising or related to the use of the Material.",
	},
	{
		head: "Assignment",
		desc: "You may not transfer, assign, or delegate these Terms or your rights or obligations hereunder or your Account in any way (by operation of law or otherwise). To the extent permitted by applicable law, we may transfer, assign, or delegate these Terms and our rights and obligations hereunder without your consent.",
	},
	{
		head: "Indemnity",
		desc: "You agree to defend, indemnify, and hold harmless SnapPurse and Sterling Bank Plc, its employees, officers, directors, agents, its affiliates and third-party service providers from and against any and all claims, suits, liabilities, damages (actual and consequential), losses, fines, penalties, costs, and expenses (including reasonable attorneys’ fees) arising from or in any way related to any claims relating to your use of the Services, violation of these Terms, applicable law or any third-party rights or claims, or your fraud or willful misconduct. Such indemnified parties reserve the right to assume the exclusive defense and control of any matter subject to indemnification by you, in which event you will cooperate in asserting any available defenses.",
	},
	{
		head: "Limitation of Liability",
		desc: "Under no circumstances shall we, our licensors or any third party content/ service provider be liable for any punitive, exemplary, consequential, incidental, indirect or special damages (including, without limitation, any personal injury, loss profits, business interruption, loss data or otherwise) arising from or in connection with the performance of the services, your use of or inability to use the services, whether by a breach of contract, negligence, strict liability, malpractice or otherwise, even if such party has been advised of the possibility of such damages.",
	},
	{
		head: "Force Majeure",
		desc: "To the fullest extent permitted under applicable law, we will be excused from performance under these Terms for any period that we are prevented from or delayed in performing any obligations pursuant to this Terms, in whole or in part, as a result of a Force Majeure Event. For purposes of this section, “Force Majeure Event” means an event or series of events caused by or resulting from any of the following: (a) weather conditions or other elements of nature or acts of God; (b) acts of war, acts of terrorism, insurrection, riots, civil disorders or rebellion; (c) quarantines or embargoes, (d) labor strikes; (e); (f) unauthorized access to our information technology systems by third parties; or (g) other causes beyond our reasonable control and contemplation.",
	},
	{
		head: "Waiver",
		desc: "No waiver by us of any term or condition set forth in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure by us to assert a right or provision under these Terms shall not constitute a waiver of such right or provision.",
	},
	{
		head: "Severability",
		desc: "If any provision in these terms is judicially determined to be illegal, unenforceable, or invalid in whole or in part for any reason, such illegal, unenforceable, or invalid provisions or part(s) of the same shall be stricken from these terms, and such provision shall not affect the legality, enforceability, or validity of the remainder of these terms. The stricken provision may be replaced, to the extent possible, with a legal, enforceable, and valid provision that is as similar in term to the stricken provision as is legally possible.",
	},
	{
		head: "Governing Law and Dispute Resolution",
		desc: "These Terms and your use of the Platform shall be governed by the laws of the Federal Republic of Nigeria. <br /><br /> The Parties agree that any dispute arising under or in connection with the validity, interpretation, and performance of this Agreement that cannot be resolved amicably by the Parties through negotiation within thirty (30) days shall be referred to mediation at the Lagos Multi-Door Courthouse (LMDC) for resolution under the provisions of the Lagos State Multi-Door Courthouse (LMDC) Law 2007 or its extant law.",
	},
];

export const TermsDetails = [
	{
		terms: [
			{
				title: "",
				desc: "Welcome to SnapCash-a loan service (the “Service”) provided by Sterling Bank PLC (“We”, “Our”, “Sterling” or “Us”) a company registered under the laws of the Federal Republic of Nigeria, with its address at Sterling Towers, 20 Marina, Lagos. These Terms of Use (“Terms”) constitute a legal agreement between you and Sterling Bank Plc. <br /> <br /> These Terms describe the terms and conditions applicable to a User’s ('You', 'Your') access to and use of SnapCash on web or mobile application (“Platform”) as well as our obligations with respect thereto. Your access to or use of the SnapCash services on the Platform is strictly subject to Your compliance with this Agreement (as updated periodically).  <br /> <br /> If you do not agree with the terms of this Agreement, you will be unable to access the Service or certain parts of the Platform. If you are using the Service on behalf of a business or entity, you acknowledge and agree that you have the authority to bind such business or entity and that such business or entity accepts these Terms. THIS AGREEMENT DOES NOT COVER THE LOAN APPLIED FOR OR OBTAINED THROUGH THE SnapCash PLATFORM, unless otherwise stated herein.",
				options: [],
			},
			{
				title: "ABOUT SnapCash",
				desc: "SnapCash offers quick loans to meet urgent needs at competitive interest rates, customers can access up to one hundred thousand naira (N100,000.00) instantly without any paperwork or guarantors. <br /> <br /> SnapCash is open to Sterling and Non-Sterling Bank customers over the age of 18. Interest rate is currently at 10% flat and tenor for each loan is not more than three (3) months. Customers are also charged a 1% management fee after a loan is disbursed. <br /> <br /> On SnapCash customers can also buy airtime and data as well as pay bills for themselves and other beneficiaries.",
				options: [],
			},
			{
				title: "USE OF THE SERVICE OFFERED ON THE PLATFORM",
				desc: "We make no representations or warranties as to the accuracy of the content of the Platform and assume no responsibility for errors or omissions in it. We also do not make any warranty that any part of the Platform will operate uninterrupted or error free and we accept no liability for loss or damage caused from any interruption or error on any part of the Platform. We reserve the right to refuse to provide access to the Platform to any individual at any time without explanation, consequence or liability; and to remove a service from the Platform with no obligation to give advance notice, and we shall not be liable for losses, costs or expenses arising from any such refusal or removal. You understand and agree that you assume all risks associated with the use of the Platform.",
				options: [],
			},
			{
				title: "ELIGIBILITY TO USE THE PLATFORM",
				desc: "The Platform is available only to registered users. By opening an account and using the Service, you represent and warrant that (1) you are at least 18 years of age; (2) you are a Nigerian or resident in Nigeria and (3) that all information you have provided in your profile is complete and accurate. By accepting these Terms, you acknowledge that your profile information will be stored in the the SnapCash database.",
				options: [],
			},
			{
				title: "SERVICE OFFERINGS ON THE PLATFORM",
				desc: "There are four variants of SnapCash as follows:",
				options: [
					{
						heading: "Snap Basic",
						desc: "Every first-time user starts here and they can access a loan of up to N5,000 with a maximum tenor of fifteen (15) days at an interest rate of 10% flat.",
					},
					{
						heading: "Snap Chief",
						desc: "On Snap Chief customers can access up to N25,000 with a maximum tenor of three (3) months to be repaid in not more than three (3) installments. <br /> <br /> Customers who want to access Snap Chief are expected to have taken at least one Snap Basic loan, paid their rentals on time and completed a minimum of seven (7) transactions on their accounts monthly.",
					},
					{
						heading: "Snap Pro",
						desc: "On Snap Pro customers can access up to N75,000 with a maximum tenor of three (3) months to be repaid in not more than three (3) installments. <br /> <br /> Customers who want to access Snap Pro are expected have taken at least two (2) Snap Chief loans, paid their rentals on time and completed a minimum of seven (7) transactions on their accounts monthly.",
					},
					{
						heading: "Snap Veteran",
						desc: "Every first-time user starts here and they can access a loan of up to N5,000 with a maximum tenor of fifteen (15) days at an interest rate of 10% flat.",
					},
				],
			},

			{
				title: "ACCOUNT ENROLLMENT",
				desc: "As a condition for use of the Platform, you agree to (a) provide accurate, current and complete information about you as may be prompted by any registration forms on the Platform; (b) maintain the security of your password and identification; (c) maintain and promptly update your registration data, and any other information you provide to us to keep it accurate, current and complete; (d) regularly review these Terms of Use for updates and changes; and (e) be fully responsible for all use of your account and for any actions that take place using your account. If any information you provide is untrue, inaccurate, not current, or incomplete, we have the right to cancel your registration, reject any application you have submitted, terminate any agreement we have with you, and restrict your future use of the Platform and our products and services. We reserve the right to reject any registration and/or decline any application for a loan. <br /> <br /> In exchange for your use of the Service and, if applicable, in order for providers to send notices to you. You agree to: (i) provide true, accurate, current and complete information about yourself as prompted by our Account enrolment form and (ii) each time you log on, maintain and promptly update such Account information to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or we have reasonable grounds to suspect that such Account information is untrue, inaccurate, not current or incomplete, we reserve the right to refuse any future request(s) to use the Service.",
				options: [],
			},

			{
				title: "YOUR PRIVACY",
				desc: "We take the privacy of our Users very seriously. You understand that by using the Services, you consent to the collection, use, storage, and disclosure of your information. For information on how we use your data, please refer to our Privacy Policy.",
				options: [],
			},
		],

		termsTwo: [
			{
				title: "PROHIBITED USE OF THE PLATFORM",
				desc: "You agree and undertake that not to:",
				options: [
					{
						list: "Interfere, disrupt or attempt to gain unauthorised access to other accounts on SnapCash.",
					},
					{
						list: "Use any device, software or routine, including but not limited to viruses, trojan horses, worms, time bombs, and cancelbots, which damage or hinder, or are intended to damage or hinder, the proper working of the Platform, or to unlawfully intercept or expropriate any software, data or other information from the web site.",
					},
					{
						list: "Take any action that imposes an unreasonable or disproportionate load on the Platform.",
					},
					{
						list: "Post, email or otherwise transmit any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment.",
					},
					{
						list: "Forge headers or otherwise manipulate identifiers in order to disguise the origin of any information transmitted through the Platform.",
					},
					{
						list: "“Frame” or “mirror” or re-engineer any part of the Platform, without our prior written authorization.",
					},
					{
						list: "Modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile or otherwise disassemble or modify any portion of the Platform or any software used on or for the Service or cause or enable others to do so",
					},
					{
						list: "Use the Platform if you are prohibited under applicable law from using the Platform.",
					},
					{
						list: "Conduct activities that may be harmful to other users of SnapCash or that could damage the reputation of the Platform.",
					},
					{
						list: "Use or attempt to use another's SnapCash account, service or system without authorization from SnapCash  or create a false identity on the Platform.",
					},
					{
						list: "Upload, post, transmit, share, store or otherwise make available content that would constitute, encourage or provide instructions for a criminal offense, violate the rights of any party, or that would otherwise create liability or violate any local, state, national or international law",
					},
				],
			},
		],

		termsThree: [
			{
				title: "LIMITATION ON USE",
				desc: "You agree to use the Platform only for lawful purposes. You are prohibited from any use of the Platform that would constitute a violation of any applicable law, regulation, rules or regulation. Any unauthorized use of the Platform, including but not limited to unauthorized entry into SnapCash systems, misuse of passwords, or misuse of any information posted on the Platform is strictly prohibited.",
				options: [],
			},
			{
				title: "ACCESS",
				desc: "You agree that the Platform may be inaccessible or inoperable from time to time for many reasons, including:  equipment malfunctions, periodic maintenance procedures or repairs which may be undertaken from time to time; causes beyond our control or which are not reasonably foreseeable by us; or at other times at at our sole discretion. <br /> <br /> We reserve the right, in our sole discretion and without notice, at any time and for any reason, to: remove or disable access to all or any portion of the Platform; or suspend user’s access to or use of all or any portion of the Platform.",
				options: [],
			},
			{
				title: "LICENSED USE",
				desc: "The content and works (including design, text, graphics and the selection and arrangement) on the Platform are exclusively owned by us. The duplication, processing, distribution and any kind of exploitation of the Platform’s content (to the extent not permitted by copyright laws), require the prior written consent of Sterling Bank Plc. Any use of the content not expressly permitted by these Terms of Use and Cookie Policy is a breach of these Terms of Use and Cookie Policy and may violate copyright, trademark and other laws. You must not use any part of the materials on the Platform for commercial purposes without obtaining a licence to do so from us or our licensors.",
				options: [],
			},
			{
				title: "INVOLVEMENT WITH THIRD PARTIES",
				desc: "",
				options: [
					{
						heading: "Links to Third Party Sites",
						desc: "Linked sites are not under our control and we are not responsible for the contents of any linked site. We may provide links only as a convenience, and such inclusion of any link does not imply endorsement by Us of the site or any part of its contents.",
					},
					{
						heading: "Items of Third Parties",
						desc: "Some of our Services allow users to make or process transactions for Items ('Transactions') and may allow users to upload or route various information including but not limited to information on Items or information relating to proposed or actual Transactions ('Content'). You acknowledge that, except for Items clearly identified as being those of Sterling Bank Plc. we do not operate, control or endorse any Items listed on the Platform or processed by the Platform. Except for Sterling Bank PLC items, all transactions are solely between You and other members and we have no responsibility whatsoever relating to the Items. 'Items' means all products, services, or information posted on the Platform.",
					},
					{
						heading: "Links to Third Party Sites",
						desc: "Linked sites are not under our control and we are not responsible for the contents of any linked site. We may provide links only as a convenience, and such inclusion of any link does not imply endorsement by Us of the site or any part of its contents.",
					},
				],
			},
		],

		termsFour: [
			{
				title: "DISCLAIMERS",
				options: [
					{
						list: "The Platform may be under constant upgrades, and some functions and features may not be fully operational during periods of upgrade or software maintenance.",
					},
					{
						list: "We disclaim any liability arising due to the vagaries that can occur in the electronic distribution of information.",
					},
					{
						list: "You acknowledge that third party services are available on the Platform. We may have formed partnerships or alliances with some of these third parties from time to time in order to facilitate the provision of certain services to you. However, you acknowledge and agree that at no time are we making any representation or warranty regarding any third party's services nor will we be liable to you or any third party for any consequences or claims arising from or in connection with such third party including, and not limited to, any liability or responsibility for, death, injury or impairment experienced by you or any third party. You hereby disclaim and waive any rights and claims you may have against us with respect to third party’s services.",
					},
					{
						list: "We do not, in any way, endorse any information or service offered or described on the Platform. In no event shall we be liable to you or any third party for any decision made or action taken in reliance on such information.",
					},
					{
						list: "You assume all responsibility and risk with respect to your use of the Platform. The services are provided and available “as is,” and “as available”. You understand and agree that, to the fullest extent permitted by law, we disclaim all warranties, representations and endorsements, express or implied, with regard to the Platform, including, without limitation, implied warranties of title, merchantability, non-infringement and fitness for a particular purpose.",
					},
					{
						list: "We do not warrant use of the Platform will be uninterrupted or error-free or that errors will be detected or corrected. We do not assume any liability or responsibility for any computer viruses, bugs, malicious code or other harmful components, delays, inaccuracies, errors or omissions, or the accuracy, completeness, reliability or usefulness of the information disclosed or accessed through the services.",
					},
					{
						list: "In no event, under no legal or equitable theory (whether tort, contract, strict liability or otherwise), shall we or any of our respective employees, directors, officers, agents or affiliates, be liable hereunder or otherwise for any loss or damage of any kind, direct or indirect, in connection with or arising from the use of the Platform / services or our agreement with you concerning the services, including, but not limited to, (i) the use of or inability to use the Platform, the service, or the content, (ii) any transaction conducted through the Platform; (iii) any claim attributable to errors, omissions, or other inaccuracies in the Platform, the service and/or the content, (iv) unauthorized access to or alteration of your transmissions or data, or (v) any other matter relating to the Platform, the service, or the content, and any compensatory, direct, consequential, incidental, indirect, special or punitive damages, lost anticipated profits, loss of goodwill, loss of data, business interruption, accuracy of results, or computer failure or malfunction, even if we have been advised of or should have known of the possibility of such damages.",
					},
				],
			},
		],

		termsFive: [
			{
				title: "DATA INTEGRITY AND STORAGE",
				options: [
					{
						heading: "Data Integrity",
						desc: "You are responsible for confirming the accuracy of any data you send to or receive from the Platform, and for maintaining a means external to the Platform, for the reconstruction of your lost data. We have procedures to assist Your efforts to confirm that Your Data is accurate, current, and complete.",
					},
					{
						heading: "Storage of Data",
						desc: "The Platform will allow You to access Data You send to or receive from the Platform for a limited period of time, as may be announced by us from time to time and which may vary for specific Platform or functions. Notwithstanding the foregoing, wwill not delete the transaction Data you retain on the Ariba Network that has been stored on the Ariba Network for less than eighteen-months, provided that your account is active and in good standing. You are solely responsible for saving all of Your Data. Therefore, You should take proactive measures to store Your Data within Your own computers, in order to preserve the accessibility of such Data beyond the Platform retention period applicable to You.",
					},
					{
						heading: "INDEMNIFICATION",
						desc: "Except as otherwise set forth in this Agreement, you agree to release, indemnify, defend, and hold Us harmless from and against any claims, liabilities, actions, proceedings, damages, losses, and expenses (including without limitation to attorneys’ fees), or losses of any kind arising out of or in relation to: (a) any breach of the Agreement by you; (b) your actions and omissions in connection with the Services; (c) any inaccuracies on your loan application; (d) your authorization of a credit inquiry (e) our actions or omissions, provided that they are taken/omitted in accordance with this Agreement or your instructions. This provision shall survive the termination of this Agreement.",
					},
					{
						heading: "SEVERABILITY",
						desc: "If any of these terms should be determined to be illegal, invalid or otherwise unenforceable by reason of the laws of any state or country in which these terms are intended to be effective, then to the extent and within the jurisdiction which that term is illegal, invalid or unenforceable, it shall be severed and deleted and the remaining of the Terms shall survive, remain in full force and effect and continue to be binding and enforceable.",
					},
					{
						heading: "NON-ASSIGNMENT",
						desc: "You may not transfer, assign, or delegate these Terms or your rights or obligations herein or your Account, in any way (by operation of law or otherwise). To the extent permitted by applicable law, we may transfer, assign, or delegate these Terms and our rights and obligations hereunder without your consent.",
					},
					{
						heading: "FORCE MAJEURE",
						desc: "To the fullest extent permitted under applicable law, we will be excused from performance under these Terms for any period that we are prevented from or delayed in performing any obligations pursuant to this Terms, in whole or in part, as a result of a Force Majeure Event. For purposes of this section, “Force Majeure Event” means an event or series of events caused by or resulting from any of the following: (a) weather conditions or other elements of nature or acts of God; (b) acts of war, acts of terrorism, insurrection, riots, civil disorders or rebellion; (c) quarantines or embargoes, (d) labor strikes; (e); (f) unauthorized access to our information technology systems by third parties; or (g) other causes beyond our reasonable controland contemplation.",
					},
					{
						heading: "ANTI-MONEY LAUNDERING",
						desc: "You are now subject to and will at all material times in the future comply with all applicable laws, rules and regulations concerning the detection of financial crimes, prevention of terrorism and anti-money laundering and you acknowledge that any transaction we deal with on your instructions will be covered by statutory and Regulatory requirements relating to money laundering and combating terrorist financing. If any fraudulent activity or breach is associated with the operation of your account/wallet, you agree that we have the right to apply restrictions to your account/wallet and report to appropriate law enforcement agencies in line with extant laws.",
					},

					{
						heading: "GOVERNING LAW AND DISPUTE RESOLUTION",
						desc: "These Terms are governed by the laws of the Federal Republic of Nigeria. We will use our best efforts to resolve any issue that may arise from the use of the Platform. However, we realize that there may be rare cases where we may not be able to resolve an issue to your satisfaction. In the event we cannot resolve a dispute between us, you agree that all matters related to any use or access to the Platform or the services shall be referred to courts in Nigeria.",
					},
					{
						heading: "MODIFICATIONS TO THE TERMS AND CONDITIONS",
						desc: "We may change these terms from time to time. This is solely at our discretion and changes will be effective when posted on the Platform with no other notice provided. Please check the Terms regularly for updates as changes shall be effective immediately, and, we may indicate the date of such revision. <br /> <br /> In addition, we may change, suspend or discontinue any aspect of the Services at any time, including the availability of any feature, or content. We may also impose limits on certain features and service or restrict your access to parts or all of the Platform or the Services provided without notice or liability. <br /> <br /> If you keep using the Platform after a change, that means you accept the new terms.",
					},
					{
						heading: "ENQUIRIES AND COMPLAINTS",
						desc: "We appreciate it greatly if you notify us of any inquiries or issues regarding the M-token service or these Terms, as this gives us the opportunity to seek a suitable solution. It also gives us the opportunity to improve the Service offered to you and other customers. We also have an internal complaints procedure to effectively and promptly address any complaints. <br /> <br /> You may reach us by sending a mail to <a style='text-decoration: underline'; href='mailto:hello@SnapCash.ng'>hello@SnapCash.ng </a> and include the following information: your name, telephone number and a description of your concern or calling us on <a  style='text-decoration: underline'; href='tel:+234 201 700 4271'>02017004271</a>",
					},
				],
			},
		],
	},
];

export const LicenseDetails = [
	{
		licenceOne: [
			{
				title: "Software License",
				desc: "In this Agreement, 'Software' means the SnapCash Mobile Application which may include related printed material, media and any other components and/or software modules, including but not limited to required drivers. It could also include any necessary updates and upgrades.",
				options: [],
			},
			{
				title: "Grant of License",
				desc: "By downloading via Google Play Store or Apple Store, installing, copying or using the Software, we grant you a limited, non-transferable, non-exclusive, royalty-free license to install and use the Software in accordance with the terms of this Agreement. This Agreement shall also govern any and all updates and upgrades that would replace, overwrite and/or supplement the original installed version of the Software unless those other upgrades are covered under a separate license, at which those terms of that license will govern.",
				options: [],
			},
		],

		licenceTwo: [
			{
				title: "Terms of License",
				desc: "",
				options: [
					{
						title: "Permitted Uses",
						desc: "You may install and use the Software solely for your personal and non- commercial use. A commercial profit-oriented business is strictly prohibited.",
						options: [],
					},
					{
						title: "Restricted Uses",
						desc: "",
						options: [
							{
								list: "No Distribution, etc.: You may not distribute, sub-license, loan, or sell the Software or any of its content that is contained or displayed in it to any third party.",
							},
							{
								list: "No Modification: You may not modify, alter, or create any derivative works of the Software, without the prior written consent of the company.",
							},
							{
								list: "No Reverse Engineering: You may not reverse, engineer, decompile, decode, decrypt, disassemble, or derive any source code from or about the Software.",
							},
							{
								list: "Proprietary Notices: You may not remove, alter, or obscure any copyright, trademark, or other proprietary rights notice on or in the Software.",
							},
						],
					},
				],
			},
		],

		licenceThree: [
			{
				title: "Support and Maintenance",
				desc: "We will provide you with the necessary support and maintenance services as follows:",
				options: [
					{
						list: "Email Support: During our normal business hours, we will attempt to respond to technical questions that you email to us within 24 hours under the conducive atmosphere of operation.",
					},
					{
						list: "Patches and Fixes: We will attempt to correct issues identified in the Software by providing patches and fixes.",
					},
					{
						list: "Upgrade: Where applicable, we will attempt to distribute Software upgrades on a regular basis.",
					},
				],
			},
		],

		licenceFour: [
			{
				title: "Updates",
				desc: "We may find the need from time to time to make available to all license holders updates for the offered content, in accordance with the herein contained terms and conditions of this Agreement. It shall be at our sole discretion to make conditional releases of said upgrade to you upon your acceptance of another end user license agreement. Should you elect to install and make use of these updates, you are therefore agreeing to be subject to all applicable license, terms and conditions of this Agreement and/or any other agreement.",
				options: [],
			},
			{
				title: "Information",
				desc: "You hereby authorize us to lawfully access, use or disclose your details processed on the Software for our use. This use may include but shall not be limited to such purposes as are necessary to promote or sustain the Software or its promotion. You hereby unequivocally waive any claim you may have against the company arising from any such access, use or disclosure.",
				options: [],
			},
			{
				title: "Term",
				desc: "This Agreement is effective upon acceptance and installation of the Software (the 'Effective Date') and will continue until terminated in accordance with the terms of this agreement.",
				options: [],
			},
			{
				title: "Personal Data",
				desc: "",
				options: [
					{
						list: "Personal data is all information that directly or indirectly may refer to the Software User which he/she has provided in the course of this Agreement or use of the Software. Such includes Contact information [name, title, address, email address, mobile phone number etc., date of birth, employment information [name, address, phone number and email address of employee], financial information [information about the User’s income and any charges, where available.",
					},
					{
						list: "We, where necessary, will process the personal data in order to provide a simplified service and in order to simplify the process. We will process the personal data for confirming your identity, administering the customer relationship, preventing misuse or improper use of our Software, for the prevention of money laundering, customer analysis, assessment, risk analysis and management, business development, for commercial and marketing purposes and for complying with applicable laws.",
					},
					{
						list: "By accepting the Terms of this Software, you agree that we may use and/or reuse data provided or previously provided and we may carry out enquiries to help on your use of the Software. Where available, we will use such information to confirm your identity and simplify the use of our service.",
					},
				],
			},
			{
				title: "Information Sharing and Disclosure",
				desc: "We will not share your Personal Data with anyone. However, we may share such information to third parties only on 'Need basis' such as when: a. We have your consent to share the information b. The law requires it of us c. We respond to court orders or legal processes; or d. We find that your actions violate any part of the terms of the use of the Software.",
				options: [],
			},
			{
				title: "Warranties",
				desc: "",
				options: [
					{
						list: "This Software and its content, with all its faults, defects, and errors, are provided 'As Is' and without any warranty.",
					},
					{
						list: "We do not promise that the Software will be free of bugs, errors, viruses, or other defects. We will not be liable for your use of or inability to use the Software, its content, or any associated service. Disclaimer of All Other Warranties: We are not making any other express or implied warranties, including any implied warranties of title, quiet enjoyment, quiet possession, merchantability or fitness for a particular purpose, except as stated. The entire risk as to the quality of or performance of the Software, if any, rests with you.",
					},
				],
			},
			{
				title: "Acknowledgements",
				desc: "",
				options: [
					{
						list: "Ownership of Software: We own the Software and all intellectual property rights in and to it.",
					},
					{
						list: "Third-Party Software: The Software may now or later contain third party software that requires notices or additional terms. If so, those notices and additional terms are and will be located on our website and are incorporated by reference into this Agreement.",
					},
					{
						list: "Consent to Use of Data: You agree that we and our affiliates may collect and use technical information gathered as part of our support services. We may use this information, but only to improve our products and services. We will not disclose this information in a form that personally identifies you.",
					},
					{
						list: "Compliance with Laws: In the use of the Software, you must comply with all applicable laws and regulations (including those relating to the import and export of software).",
					},

					{
						list: "Limitation of Liability: We will not be liable for breach-of-contract or damages that could not reasonably have been foreseen on entering into this Agreement.",
					},
				],
			},
		],

		licenceFive: [
			{
				title: "Termination",
				desc: "",
				options: [
					{
						list: "Termination by Us: We may terminate this Agreement upon five (5) days' prior written notice to you. Upon notice of such termination, the installation shall be disconnected or disabled. We shall also have the power to summarily terminate this Agreement devoid of any notice.",
					},
					{
						list: "Termination by You: You may terminate this Agreement by:",
					},
					// {
					// 	innerOptions: [
					// 		{
					// 			list: "permanently destroying all copies of the Software in your possession or control",
					// 		},
					// 		{
					// 			list: "removing the Software from any hard drive on which it is installed, and",
					// 		},
					// 		{
					// 			list: "giving us Notice certifying that you have taken these steps.",
					// 		},
					// 	],
					// },
				],
			},
		],

		licenseSix: [
			{
				title: "General",
				desc: "",
				options: [
					{
						list: "Entire Agreement: This Agreement contains all the terms between the Parties relating to its subject matter. It replaces all previous discussions, understandings, and agreements.",
					},
					{
						list: "Amendment: We may amend this Agreement on one or more occasions by posting amendments to our website. If you do not accept amendments to this agreement, then this license will immediately terminate.",
					},
					{
						list: "Assignment: You may not assign this Agreement or any of your rights under it. We may assign this Agreement or any of our rights at any time without notice or the need for your consent.",
					},
					{
						list: "Severability: If any part of this Agreement is declared unenforceable or invalid, the remainder will continue to be valid and enforceable.",
					},
					{
						list: "Waiver: A party's failure or neglect to enforce any rights under this Agreement will not be deemed to be a waiver of that party's rights.",
					},
					{
						list: "Notices: We may deliver any notice required by this Agreement via pop-up window, dialog box, or other on-screen device, even though you may not receive the notice until you next launch the Software. Any such notice will be deemed delivered on the date we first make it available through the Software.",
					},
					{
						list: "Governing Law: This Agreement will be governed and construed in accordance with the laws of the Federal Republic of Nigeria, without regard to its conflict of laws rules.",
					},
					{
						list: "Headings: The headings used in this Agreement and its division into sections, schedules, and other subdivisions do not affect its interpretation.",
					},
				],
			},
		],
	},
];
