import { Box, Spinner } from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SliderCard } from "./SliderCard";
import { useEffect, useState, useContext } from "react";
import { ProductsInterface } from "@/types/interfaces";
import { AttributesContext, ShowModalContext } from "@/utils/contexts";


const SliderLayout = () => {
	const { setAttribute } = useContext(AttributesContext);
	const { setShowModal } = useContext(ShowModalContext);

	const [activeProducts, setActiveProducts] = useState(JSON.parse(sessionStorage.getItem("products") as string));

	const handleShowModal = (product_id: number | null) => {
		const product_key = "pk_live_DxhIehsdX7VB5bN7qcJBMUpEsNe90Qe8Lhi418XT";

		setShowModal(true);
		setAttribute({ product_key, product_id });
	};


	useEffect(() => {
		const intervalId = setInterval(() => {
		  const productListString = sessionStorage.getItem("products");
		  if (productListString) {
			const productList = JSON.parse(productListString);
			setActiveProducts(productList);
		  }
		}, 3000);

		if (sessionStorage.getItem("products")) {
			clearInterval(intervalId);
		}
	  
		return () => {
			clearInterval(intervalId);
		};
	  }, []);	  

	  
	const settings = {
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 1,
		initialSlide: 0,
		infinite: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	return (
		<>
		<Box p="0" bg="brand.white100">
			{activeProducts ? 
			<Slider {...settings}>
				{activeProducts.map((product: ProductsInterface) => (
					<SliderCard
					key={product.name}
					title={product.name}
					descOne={product.description}
					rate={product.interest_rate}
					setShowModal={() => handleShowModal(product.id)}
					/>
				))}
			</Slider>
			:  <Spinner
			thickness='4px'
			speed='0.65s'
			emptyColor='gray.200'
			color='purple'
			size='xl'
			display={"block"}
			margin={"5rem auto 0 auto"}
			/>}
		</Box>
		</>
	);
};

export { SliderLayout };
