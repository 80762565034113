import AccessImage from "@/assets/images/access-img.webp";
import DocumentImage from "@/assets/images/document-img.webp";
import AgreeableImage from "@/assets/images/agreeable-img.webp";
import AirtimeImage from "@/assets/images/airtime-img.webp";
import { AccessDataProps } from "@/types/interfaces";

export const AccessDataOne: AccessDataProps = {
	title: "Quick loans at your fingertips!",
	desc: "Get credited in less than 5 minutes.",
	buttonText: "Apply for a loan",
	backgroundImage: AccessImage,
};

export const AccessDataTwo: AccessDataProps = {
	title: "Swift and Easy",
	desc: "No Paperwork, no Collateral and no Guarantors.",
	buttonText: "Get started now",
	backgroundImage: DocumentImage,
};

export const AccessDataThree: AccessDataProps = {
	title: "Easy to pay back",
	desc: "Interest rates so low you won’t feel it.",
	buttonText: "Apply for a loan",
	backgroundImage: AgreeableImage,
};

export const AccessDataFour: AccessDataProps = {
	title: "Airtime, data purchase on the go",
	desc: "Buy airtime and pay bills for yourself and loved ones.",
	buttonText: "Download app now",
	backgroundImage: AirtimeImage,
};
