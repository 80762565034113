import { Box, Stack } from "@chakra-ui/react";
import { Footer } from "@/layouts/Footer";
import { HeaderLayout } from "@/components/layout/HeaderLayout";
import { PersonalLoan } from "@/components/about/PersonalLoans";
import Logo from "@/assets/images/snapcash-footer-logo.webp";
import ArrowDownBlack from "@/assets/svgs/arrow-down-black-icon.svg";
import ArrowUpBlack from "@/assets/svgs/arrow-up-black-icon.svg";
import { FinancialAccess } from "@/components/about/FinancialAccess";

const AboutUs = () => {
	return (
		<Box p="0">
			<HeaderLayout
				bg="brand.white100"
				src={Logo}
				color="brand.dark100"
				arrowDown={ArrowDownBlack}
				arrowUp={ArrowUpBlack}
			/>
			<Stack
				maxW="1280px"
				mx="auto"
				p={["0rem 1rem 0rem", "2rem 2rem 0rem", "4rem 3rem 4rem"]}
				pos="relative"
			>
				<Box bg="brand.white100" h="600px" mb="3rem">
					<FinancialAccess />
				</Box>

				<Box>
					<PersonalLoan />
				</Box>
			</Stack>
			<Footer />
		</Box>
	);
};

export default AboutUs;
