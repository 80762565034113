import { Box, Divider, Flex, Image, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import Logo from "@/assets/images/snapcash-footer-logo.webp";
import ArrowUpBlack from "@/assets/svgs/arrow-up-black-icon.svg";
import InstagramIcon from "@/assets/svgs/instagram-icon.svg";
import TwitterIcon from "@/assets/svgs/twitter-icon.svg";
import FacebookIcon from "@/assets/svgs/facebook-icon.svg";
import SterlingLogo from "@/assets/images/sterling-logo.webp";

const Footer = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	const scrollToTop = () => {
		window.scrollTo(0, 0);
	};

	return (
		<Box p="0" bg="brand.white200" id="contact">
			<Box
				maxW="1480px"
				mx="auto"
				p={["2rem 1rem 2rem", "2rem 2rem 2rem", "4rem 3rem 4rem"]}
			>
				<Flex
					flexDir={["column", "column", "column", "column", "row"]}
					justifyContent="space-between"
				>
					<Box w={["100%", "100%", "90%", "80%", "28%"]}>
						<Box>
							<Link to="/">
								<Image src={Logo} alt="Footer Logo" loading="lazy" />
							</Link>
						</Box>

						<Box mt="2rem">
							<Text
								fontWeight="400"
								fontSize={[".95rem", ".95rem", ".95rem", "1rem"]}
								color="brand.dark200"
							>
								SnapCash offers quick loans to meet urgent needs at low interest
								rates.
							</Text>
						</Box>
					</Box>

					<Flex
						justifyContent="space-between"
						flexDir={["column", "column", "column", "row"]}
						w={["100%", "100%", "100%", "100%", "55%"]}
						mt={["2rem", "2rem", "2rem", "2rem", "0rem"]}
					>
						<Box color="brand.dark200" w="max-content">
							<Text fontWeight="700">Loans</Text>

							<a
								href="https://app.snapcash.ng"
								target="_blank"
								rel="noreferrer"
							>
								<Text fontWeight="400" fontSize="0.875rem" mt=".7rem">
									Apply now
								</Text>
							</a>
							<Link to="/faq">
								<Text fontWeight="400" fontSize="0.875rem" mt=".7rem">
									FAQs
								</Text>
							</Link>
						</Box>

						<Box
							color="brand.dark200"
							mt={["2rem", "2rem", "2rem", "0"]}
							w="max-content"
						>
							<Text fontWeight="700">SnapPurse</Text>

							<Link to="/merchant-faqs">
								<Text fontWeight="400" fontSize="0.875rem" mt=".7rem">
									SnapPurse FAQs
								</Text>
							</Link>
							<Link to="/merchant-terms">
								<Text fontWeight="400" fontSize="0.875rem" mt=".7rem">
									SnapPurse Terms of Use
								</Text>
							</Link>

							<Link to="/merchant-privacy">
								<Text fontWeight="400" fontSize="0.875rem" mt=".7rem">
									SnapPurse Privacy Policy
								</Text>
							</Link>
						</Box>

						<Box
							color="brand.dark200"
							mt={["2rem", "2rem", "2rem", "0"]}
							w="max-content"
						>
							<Text fontWeight="700">Legal</Text>
							<Link to="/legal#terms">
								<Text fontWeight="400" fontSize="0.875rem" mt=".7rem">
									Terms of use
								</Text>
							</Link>

							<Link to="/legal#privacy">
								<Text fontWeight="400" fontSize="0.875rem" mt=".7rem">
									Privacy policy
								</Text>
							</Link>
						</Box>

						<Box
							color="brand.dark200"
							mt={["2rem", "2rem", "2rem", "0"]}
							w="max-content"
						>
							<Text fontWeight="700">Downloads</Text>
							<a
								href="https://play.google.com/store/apps/details?id=com.snapcashsterling"
								target="_blank"
								rel="noreferrer"
							>
								<Text fontWeight="400" fontSize="0.875rem" mt=".7rem">
									Google Play Store
								</Text>
							</a>

							<a
								href="https://apps.apple.com/ng/app/snapcash-loans-in-a-snap/id1634947408"
								target="_blank"
								rel="noreferrer"
							>
								<Text fontWeight="400" fontSize="0.875rem" mt=".7rem">
									Apple App Store
								</Text>
							</a>

							<a
								href="https://app.snapcash.ng"
								target="_blank"
								rel="noreferrer"
							>
								<Text fontWeight="400" fontSize="0.875rem" mt=".7rem">
									Web App
								</Text>
							</a>
						</Box>

						<Box
							color="brand.dark200"
							mt={["2rem", "2rem", "2rem", "0"]}
							w="max-content"
						>
							<Text fontWeight="700">Contact</Text>
							<a href="mailto:hello@snapcash.ng">
								<Text fontWeight="400" fontSize="0.875rem" mt=".7rem">
									hello@snapcash.ng
								</Text>
							</a>

							<a href="tel:+2342017004271">
								<Text fontWeight="400" fontSize="0.875rem" mt=".7rem">
									+234 201 700 4271
								</Text>
							</a>

							<a
								href="https://sterling.ng/support/contact-us/"
								rel="noopener noreferrer"
								target="_blank"
							>
								<Text fontWeight="400" fontSize="0.875rem" mt=".7rem">
									Complaints
								</Text>
							</a>
						</Box>
					</Flex>
				</Flex>

				<Divider color="brand.gray300" mt="3rem" mb="2rem" />

				<Flex
					flexDir={["column", "column", "column", "row"]}
					justifyContent="space-between"
				>
					<Box fontWeight="400">
						<Text>Powered by</Text>
						<Flex alignItems="center" mt=".5rem">
							<Flex alignItems="center" justifyContent="center">
								<Image
									w="25px"
									src={SterlingLogo}
									alt="SnapCash is powered by Sterling Bank Plc."
									loading="lazy"
								/>
								<Text ml=".5rem">Sterling Bank Plc. </Text>
							</Flex>

							<Text ml=".5rem">Copyright {new Date().getFullYear()} </Text>
						</Flex>
					</Box>

					<Flex
						w={["90%", "80%", "40%", "40%", "23%"]}
						justifyContent="space-between"
						alignItems="center"
						mt={["1rem", "1rem", "1rem", "0"]}
					>
						<Box>
							<Text>Back to top</Text>
						</Box>
						<Box
							onClick={scrollToTop}
							cursor="pointer"
							bg="brand.lime100"
							borderRadius="50%"
							p=".6rem"
						>
							<Image
								src={ArrowUpBlack}
								h="10px"
								alt="Arrow Black Up"
								loading="lazy"
							/>
						</Box>

						<a href=" https://www.instagram.com/sterlingbank/?hl=en">
							<Box
								cursor="pointer"
								bg="brand.purple100"
								borderRadius="50%"
								p=".5rem"
							>
								<Image
									src={InstagramIcon}
									h="13px"
									alt="Instagram Icon"
									loading="lazy"
								/>
							</Box>
						</a>

						<a href="https://twitter.com/sterling_bankng?s=21">
							<Box
								cursor="pointer"
								bg="brand.purple100"
								borderRadius="50%"
								p=".5rem"
							>
								<Image
									src={TwitterIcon}
									h="13px"
									alt="Twitter Icon"
									loading="lazy"
								/>
							</Box>
						</a>

						<a href="https://m.facebook.com/SterlingBankNigeria/">
							<Box
								cursor="pointer"
								bg="brand.purple100"
								borderRadius="50%"
								p=".38rem .7rem"
							>
								<Image
									src={FacebookIcon}
									h="17px"
									alt="Facebook Icon"
									loading="lazy"
								/>
							</Box>
						</a>
					</Flex>
				</Flex>
			</Box>
		</Box>
	);
};

export { Footer };
