import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Box,
	Stack,
	Text,
	Tabs,
	TabList,
	Tab,
} from "@chakra-ui/react";
import { useState } from "react";
import MainLayout from "@/layouts/MainLayout";

const Faq = () => {
	const [active, setActive] = useState("first");

	const handleActivateState = (position: string) => {
		switch (position) {
			case "first":
				setActive("first");
				break;
			case "second":
				setActive("second");
				break;
			case "third":
				setActive("third");
				break;
			case "fourth":
				setActive("fourth");
				break;
			case "fifth":
				setActive("fifth");
				break;
			case "sixth":
				setActive("sixth");
				break;
			case "seventh":
				setActive("seventh");
				break;
			case "eighth":
				setActive("eighth");
				break;
			case "ninth":
				setActive("ninth");
				break;
			case "tenth":
				setActive("tenth");
				break;
			case "eleventh":
				setActive("eleventh");
				break;
			case "twelveth":
				setActive("twelveth");
				break;
			case "thirteenth":
				setActive("thirteenth");
				break;
			default:
				setActive("first");
		}
	};
	return (
		<MainLayout>
			<Box p="0" bg="brand.purple100">
				<Stack
					maxW="1280px"
					mx="auto"
					p={["0rem 1rem 2rem", "2rem 2rem 2rem", "4rem 3rem 4rem"]}
				>
					<Box>
						<Text
							fontSize={["2rem", "2rem", "3rem"]}
							color="brand.white100"
							fontWeight="700"
						>
							FAQs
						</Text>
					</Box>
				</Stack>
			</Box>

			<Stack
				maxW="1280px"
				mx="auto"
				p={["0rem 1rem 0rem", "2rem 2rem 0rem", "6rem 3rem 4rem"]}
			>
				<Box
					display="flex"
					flexDir={["column", "column", "row"]}
					justifyContent="space-between"
				>
					<Accordion
						w={["100%", "100%", "50%"]}
						order={["1", "1", "0"]}
						mb={["2rem", "2rem", "0"]}
						allowMultiple
					>
						<AccordionItem
							border="2px solid #000"
							borderRadius="12px"
							p="1rem 1rem"
							order="1"
						>
							<h2>
								<AccordionButton
									fontSize={["1rem", "1rem", "1rem", "2rem"]}
									_hover={{
										bg: "none",
									}}
									_focus={{
										boxShadow: "none",
									}}
								>
									<Box
										id="first"
										flex="1"
										textAlign="left"
										fontWeight="700"
										onClick={() => handleActivateState("first")}
										color={
											active === "first" ? "brand.purple100" : "brand.dark100"
										}
									>
										What is SnapCash?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel
								pb={4}
								fontSize={[".85rem", ".85rem", ".85rem", "1rem"]}
							>
								SnapCash offers quick loans to meet urgent needs at competitive
								interest rates.
							</AccordionPanel>
						</AccordionItem>

						<AccordionItem
							border="2px solid #000"
							borderRadius="12px"
							p="1rem 1rem"
							mt="2.5rem"
						>
							<h2>
								<AccordionButton
									fontSize={["1rem", "1rem", "1rem", "2rem"]}
									_hover={{
										bg: "none",
									}}
									_focus={{
										boxShadow: "none",
									}}
								>
									<Box
										flex="1"
										textAlign="left"
										fontWeight="700"
										onClick={() => handleActivateState("second")}
										color={
											active === "second" ? "brand.purple100" : "brand.dark100"
										}
									>
										What makes SnapCash different?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel
								pb={4}
								fontSize={[".85rem", ".85rem", ".85rem", "1rem"]}
							>
								You can get a loan within minutes
							</AccordionPanel>
						</AccordionItem>

						<AccordionItem
							border="2px solid #000"
							borderRadius="12px"
							p="1rem 1rem"
							mt="2.5rem"
						>
							<h2>
								<AccordionButton
									fontSize={["1rem", "1rem", "1rem", "2rem"]}
									_hover={{
										bg: "none",
									}}
									_focus={{
										boxShadow: "none",
									}}
								>
									<Box
										flex="1"
										textAlign="left"
										fontWeight="700"
										onClick={() => handleActivateState("third")}
										color={
											active === "third" ? "brand.purple100" : "brand.dark100"
										}
									>
										Who is eligible to request?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel
								pb={4}
								fontSize={[".85rem", ".85rem", ".85rem", "1rem"]}
							>
								Anyone who is over 18 years and has a valid Nigerian bank
								account
							</AccordionPanel>
						</AccordionItem>

						<AccordionItem
							border="2px solid #000"
							borderRadius="12px"
							p="1rem 1rem"
							mt="2.5rem"
						>
							<h2>
								<AccordionButton
									fontSize={["1rem", "1rem", "1rem", "2rem"]}
									id="fourth"
									_hover={{
										bg: "none",
									}}
									_focus={{
										boxShadow: "none",
									}}
								>
									<Box
										flex="1"
										textAlign="left"
										fontWeight="700"
										onClick={() => handleActivateState("fourth")}
										color={
											active === "fourth" ? "brand.purple100" : "brand.dark100"
										}
									>
										Is it open to non-Sterling customers?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel
								pb={4}
								fontSize={[".85rem", ".85rem", ".85rem", "1rem"]}
							>
								SnapCash is open to Sterling and non-Sterling bank customers.
							</AccordionPanel>
						</AccordionItem>

						<AccordionItem
							border="2px solid #000"
							borderRadius="12px"
							p="1rem 1rem"
							order="1"
							mt="2.5rem"
						>
							<h2>
								<AccordionButton
									fontSize={["1rem", "1rem", "1rem", "2rem"]}
									_hover={{
										bg: "none",
									}}
									_focus={{
										boxShadow: "none",
									}}
								>
									<Box
										id="first"
										flex="1"
										textAlign="left"
										fontWeight="700"
										onClick={() => handleActivateState("fifth")}
										color={
											active === "fifth" ? "brand.purple100" : "brand.dark100"
										}
									>
										How can I get SnapCash?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel
								pb={4}
								fontSize={[".85rem", ".85rem", ".85rem", "1rem"]}
							>
								SnapCash is available for download from the Google Play Store or the iOS App Store.
							</AccordionPanel>
						</AccordionItem>

						<AccordionItem
							border="2px solid #000"
							borderRadius="12px"
							p="1rem 1rem"
							mt="2.5rem"
						>
							<h2>
								<AccordionButton
									fontSize={["1rem", "1rem", "1rem", "2rem"]}
									_hover={{
										bg: "none",
									}}
									_focus={{
										boxShadow: "none",
									}}
								>
									<Box
										flex="1"
										textAlign="left"
										fontWeight="700"
										onClick={() => handleActivateState("sixth")}
										color={
											active === "sixth" ? "brand.purple100" : "brand.dark100"
										}
									>
										What is the maximum amount I can get?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel
								pb={4}
								fontSize={[".85rem", ".85rem", ".85rem", "1rem"]}
							>
								You can potentially qualify for a loan amount as high as ₦100,000. However, this often depends on your repayment history with smaller loans. Demonstrating responsible repayment behavior with smaller loan amounts can increase your chances of being approved for larger sums.
							</AccordionPanel>
						</AccordionItem>

						<AccordionItem
							border="2px solid #000"
							borderRadius="12px"
							p="1rem 1rem"
							mt="2.5rem"
						>
							<h2>
								<AccordionButton
									fontSize={["1rem", "1rem", "1rem", "2rem"]}
									_hover={{
										bg: "none",
									}}
									_focus={{
										boxShadow: "none",
									}}
								>
									<Box
										flex="1"
										textAlign="left"
										fontWeight="700"
										onClick={() => handleActivateState("seventh")}
										color={
											active === "seventh" ? "brand.purple100" : "brand.dark100"
										}
									>
										What documents do I need to provide?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel
								pb={4}
								fontSize={[".85rem", ".85rem", ".85rem", "1rem"]}
							>
								You will need to provide a valid National Identification Card as one of the primary documents for verification purposes. Additionally, depending on the specific requirements of the loan you are applying for, you may be asked to provide additional documents such as proof of income, address verification, and bank statements.
							</AccordionPanel>
						</AccordionItem>

						<AccordionItem
							border="2px solid #000"
							borderRadius="12px"
							p="1rem 1rem"
							mt="2.5rem"
						>
							<h2>
								<AccordionButton
									fontSize={["1rem", "1rem", "1rem", "2rem"]}
									id="fourth"
									_hover={{
										bg: "none",
									}}
									_focus={{
										boxShadow: "none",
									}}
								>
									<Box
										flex="1"
										textAlign="left"
										fontWeight="700"
										onClick={() => handleActivateState("eighth")}
										color={
											active === "eighth" ? "brand.purple100" : "brand.dark100"
										}
									>
										What if I want a higher amount?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel
								pb={4}
								fontSize={[".85rem", ".85rem", ".85rem", "1rem"]}
							>
								The maximum amount on SnapCash is N100,000, if you want more,
								please visit myspecta.com
							</AccordionPanel>
						</AccordionItem>

						<AccordionItem
							border="2px solid #000"
							borderRadius="12px"
							p="1rem 1rem"
							order="1"
							mt="2.5rem"
						>
							<h2>
								<AccordionButton
									fontSize={["1rem", "1rem", "1rem", "2rem"]}
									_hover={{
										bg: "none",
									}}
									_focus={{
										boxShadow: "none",
									}}
								>
									<Box
										id="first"
										flex="1"
										textAlign="left"
										fontWeight="700"
										onClick={() => handleActivateState("ninth")}
										color={
											active === "ninth" ? "brand.purple100" : "brand.dark100"
										}
									>
										How do I repay?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel
								pb={4}
								fontSize={[".85rem", ".85rem", ".85rem", "1rem"]}
							>
								Deductions are taken from the debit card added during
								registration
							</AccordionPanel>
						</AccordionItem>

						<AccordionItem
							border="2px solid #000"
							borderRadius="12px"
							p="1rem 1rem"
							mt="2.5rem"
						>
							<h2>
								<AccordionButton
									fontSize={["1rem", "1rem", "1rem", "2rem"]}
									_hover={{
										bg: "none",
									}}
									_focus={{
										boxShadow: "none",
									}}
								>
									<Box
										flex="1"
										textAlign="left"
										fontWeight="700"
										onClick={() => handleActivateState("tenth")}
										color={
											active === "tenth" ? "brand.purple100" : "brand.dark100"
										}
									>
										What is the interest rate?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel
								pb={4}
								fontSize={[".85rem", ".85rem", ".85rem", "1rem"]}
							>
								15% flat
							</AccordionPanel>
						</AccordionItem>

						<AccordionItem
							border="2px solid #000"
							borderRadius="12px"
							p="1rem 1rem"
							mt="2.5rem"
						>
							<h2>
								<AccordionButton
									fontSize={["1rem", "1rem", "1rem", "2rem"]}
									_hover={{
										bg: "none",
									}}
									_focus={{
										boxShadow: "none",
									}}
								>
									<Box
										flex="1"
										textAlign="left"
										fontWeight="700"
										onClick={() => handleActivateState("eleventh")}
										color={
											active === "eleventh"
												? "brand.purple100"
												: "brand.dark100"
										}
									>
										Are there any charges?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel
								pb={4}
								fontSize={[".85rem", ".85rem", ".85rem", "1rem"]}
							>
							  <Text fontWeight={"bold"} display={"inline"}> 2% </Text>management fee.
							</AccordionPanel>
						</AccordionItem>

						<AccordionItem
							border="2px solid #000"
							borderRadius="12px"
							p="1rem 1rem"
							mt="2.5rem"
						>
							<h2>
								<AccordionButton
									fontSize={["1rem", "1rem", "1rem", "2rem"]}
									id="fourth"
									_hover={{
										bg: "none",
									}}
									_focus={{
										boxShadow: "none",
									}}
								>
									<Box
										flex="1"
										textAlign="left"
										fontWeight="700"
										onClick={() => handleActivateState("twelveth")}
										color={
											active === "twelveth"
												? "brand.purple100"
												: "brand.dark100"
										}
									>
										What happens if I default?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel
								pb={4}
								fontSize={[".85rem", ".85rem", ".85rem", "1rem"]}
							>
								You will pay a default service charge of 1%
							</AccordionPanel>
						</AccordionItem>

						<AccordionItem
							border="2px solid #000"
							borderRadius="12px"
							p="1rem 1rem"
							mt="2.5rem"
						>
							<h2>
								<AccordionButton
									fontSize={["1rem", "1rem", "1rem", "2rem"]}
									id="fourth"
									_hover={{
										bg: "none",
									}}
									_focus={{
										boxShadow: "none",
									}}
								>
									<Box
										flex="1"
										textAlign="left"
										fontWeight="700"
										onClick={() => handleActivateState("thirteenth")}
										color={
											active === "thirteenth"
												? "brand.purple100"
												: "brand.dark100"
										}
									>
										My question was not answered, who do I contact?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel
								pb={4}
								fontSize={[".85rem", ".85rem", ".85rem", "1rem"]}
							>
								Please send an email to hello@snapcash.ng
							</AccordionPanel>
						</AccordionItem>
					</Accordion>

					<Tabs orientation="vertical" w={["100%", "100%", "30%"]}>
						<TabList
							h={["39.5%", "39.5%", "39.5%", "37.5%", "34%"]}
							borderColor="brand.dark100"
							my="2rem"
						>
							<Tab
								justifyContent="start"
								onClick={() => handleActivateState("first")}
								fontWeight="500"
								fontSize={[".8rem", ".8rem", ".8rem", "1.2rem"]}
								textAlign="left"
								color={active === "first" ? "brand.purple100" : "brand.dark100"}
								_focus={{
									boxShadow: "none",
								}}
								_active={{
									bg: "none",
								}}
								_selected={{
									color: "brand.purple100",
									borderColor: "brand.purple100",
									borderLeftWidth: "3.5px",
									// h: "12px",
								}}
							>
								What is SnapCash?
							</Tab>
							<Tab
								justifyContent="start"
								onClick={() => handleActivateState("second")}
								fontWeight="500"
								fontSize={[".8rem", ".8rem", ".8rem", "1.2rem"]}
								textAlign="left"
								color={
									active === "second" ? "brand.purple100" : "brand.dark100"
								}
								_focus={{
									boxShadow: "none",
								}}
								_active={{
									bg: "none",
								}}
								_selected={{
									color: "brand.purple100",
									borderColor: "brand.purple100",
									borderLeftWidth: "3.5px",
									// h: "12px",
								}}
							>
								What makes SnapCash different?
							</Tab>
							<Tab
								justifyContent="start"
								onClick={() => handleActivateState("third")}
								fontWeight="500"
								fontSize={[".8rem", ".8rem", ".8rem", "1.2rem"]}
								textAlign="left"
								color={active === "third" ? "brand.purple100" : "brand.dark100"}
								_focus={{
									boxShadow: "none",
								}}
								_active={{
									bg: "none",
								}}
								_selected={{
									color: "brand.purple100",
									borderColor: "brand.purple100",
									borderLeftWidth: "3.5px",
									// h: "12px",
								}}
							>
								Who is eligible to request?
							</Tab>
							<Tab
								justifyContent="start"
								onClick={() => handleActivateState("fourth")}
								fontWeight="500"
								fontSize={[".8rem", ".8rem", ".8rem", "1.2rem"]}
								textAlign="left"
								color={
									active === "fourth" ? "brand.purple100" : "brand.dark100"
								}
								_focus={{
									boxShadow: "none",
								}}
								_active={{
									bg: "none",
								}}
								_selected={{
									color: "brand.purple100",
									borderColor: "brand.purple100",
									borderLeftWidth: "3.5px",
									// h: "12px",
								}}
							>
								Is it open to non-Sterling customers?
							</Tab>

							<Tab
								justifyContent="start"
								onClick={() => handleActivateState("fifth")}
								fontWeight="500"
								fontSize={[".8rem", ".8rem", ".8rem", "1.2rem"]}
								textAlign="left"
								color={active === "fifth" ? "brand.purple100" : "brand.dark100"}
								_focus={{
									boxShadow: "none",
								}}
								_active={{
									bg: "none",
								}}
								_selected={{
									color: "brand.purple100",
									borderColor: "brand.purple100",
									borderLeftWidth: "3.5px",
									// h: "12px",
								}}
							>
								How can I get SnapCash?
							</Tab>

							<Tab
								justifyContent="start"
								onClick={() => handleActivateState("sixth")}
								fontWeight="500"
								fontSize={[".8rem", ".8rem", ".8rem", "1.2rem"]}
								textAlign="left"
								color={active === "sixth" ? "brand.purple100" : "brand.dark100"}
								_focus={{
									boxShadow: "none",
								}}
								_active={{
									bg: "none",
								}}
								_selected={{
									color: "brand.purple100",
									borderColor: "brand.purple100",
									borderLeftWidth: "3.5px",
									// h: "12px",
								}}
							>
								What is the maximum amount I can get?
							</Tab>

							<Tab
								justifyContent="start"
								onClick={() => handleActivateState("seventh")}
								fontWeight="500"
								fontSize={[".8rem", ".8rem", ".8rem", "1.2rem"]}
								textAlign="left"
								color={
									active === "seventh" ? "brand.purple100" : "brand.dark100"
								}
								_focus={{
									boxShadow: "none",
								}}
								_active={{
									bg: "none",
								}}
								_selected={{
									color: "brand.purple100",
									borderColor: "brand.purple100",
									borderLeftWidth: "3.5px",
									// h: "12px",
								}}
							>
								What documents do I need to provide?
							</Tab>

							<Tab
								justifyContent="start"
								onClick={() => handleActivateState("eighth")}
								fontWeight="500"
								fontSize={[".8rem", ".8rem", ".8rem", "1.2rem"]}
								textAlign="left"
								color={
									active === "eighth" ? "brand.purple100" : "brand.dark100"
								}
								_focus={{
									boxShadow: "none",
								}}
								_active={{
									bg: "none",
								}}
								_selected={{
									color: "brand.purple100",
									borderColor: "brand.purple100",
									borderLeftWidth: "3.5px",
									// h: "12px",
								}}
							>
								What if I want a higher amount?
							</Tab>

							<Tab
								justifyContent="start"
								onClick={() => handleActivateState("ninth")}
								fontWeight="500"
								fontSize={[".8rem", ".8rem", ".8rem", "1.2rem"]}
								textAlign="left"
								color={active === "ninth" ? "brand.purple100" : "brand.dark100"}
								_focus={{
									boxShadow: "none",
								}}
								_active={{
									bg: "none",
								}}
								_selected={{
									color: "brand.purple100",
									borderColor: "brand.purple100",
									borderLeftWidth: "3.5px",
									// h: "12px",
								}}
							>
								How do I repay?
							</Tab>

							<Tab
								justifyContent="start"
								onClick={() => handleActivateState("tenth")}
								fontWeight="500"
								fontSize={[".8rem", ".8rem", ".8rem", "1.2rem"]}
								textAlign="left"
								color={active === "tenth" ? "brand.purple100" : "brand.dark100"}
								_focus={{
									boxShadow: "none",
								}}
								_active={{
									bg: "none",
								}}
								_selected={{
									color: "brand.purple100",
									borderColor: "brand.purple100",
									borderLeftWidth: "3.5px",
									// h: "12px",
								}}
							>
								What is the interest rate?
							</Tab>

							<Tab
								justifyContent="start"
								onClick={() => handleActivateState("eleventh")}
								fontWeight="500"
								fontSize={[".8rem", ".8rem", ".8rem", "1.2rem"]}
								textAlign="left"
								color={
									active === "eleventh" ? "brand.purple100" : "brand.dark100"
								}
								_focus={{
									boxShadow: "none",
								}}
								_active={{
									bg: "none",
								}}
								_selected={{
									color: "brand.purple100",
									borderColor: "brand.purple100",
									borderLeftWidth: "3.5px",
									// h: "12px",
								}}
							>
								Are there any charges?
							</Tab>

							<Tab
								justifyContent="start"
								onClick={() => handleActivateState("twelveth")}
								fontWeight="500"
								fontSize={[".8rem", ".8rem", ".8rem", "1.2rem"]}
								textAlign="left"
								color={
									active === "twelveth" ? "brand.purple100" : "brand.dark100"
								}
								_focus={{
									boxShadow: "none",
								}}
								_active={{
									bg: "none",
								}}
								_selected={{
									color: "brand.purple100",
									borderColor: "brand.purple100",
									borderLeftWidth: "3.5px",
									// h: "12px",
								}}
							>
								What happens if I default?
							</Tab>

							<Tab
								justifyContent="start"
								onClick={() => handleActivateState("thirteenth")}
								fontWeight="500"
								fontSize={[".8rem", ".8rem", ".8rem", "1.2rem"]}
								textAlign="left"
								color={
									active === "thirteenth" ? "brand.purple100" : "brand.dark100"
								}
								_focus={{
									boxShadow: "none",
								}}
								_active={{
									bg: "none",
								}}
								_selected={{
									color: "brand.purple100",
									borderColor: "brand.purple100",
									borderLeftWidth: "3.5px",
									// h: "12px",
								}}
							>
								My question was not answered, who do I contact?
							</Tab>
						</TabList>
					</Tabs>
				</Box>
			</Stack>
		</MainLayout>
	);
};

export default Faq;
