import { PrivacyPolicyDetails } from "@/utils/Legal";
import {
	Box,
	ListItem,
	OrderedList,
	UnorderedList,
	Text,
	LinkBox,
} from "@chakra-ui/react";
import { Fragment } from "react";

const Privacy = () => {
	const mailTo = () => {
		return (
			<Text
				as="a"
				textDecoration="underline"
				cursor="pointer"
				onClick={() => (window.location.href = "mailto:hello@snapcash.ng")}
			>
				hello@snapcash.ng
			</Text>
		);
	};
	const mail = mailTo();

	const Tel = () => {
		return (
			<LinkBox
				textDecoration="underline"
				as="a"
				href="tel:+2342017004271"
			>
				02017004271
			</LinkBox>
		);
	};

	const callTel = Tel();

	return (
		<Box>
			<Text fontSize="1.5rem" fontWeight="700">
				PRIVACY POLICY
			</Text>
			<Text
				fontSize="1.2rem"
				fontWeight="600"
				mt="1rem"
				textDecoration="underline"
			>
				SNAPCASH
			</Text>

			<Box pt="1rem">
				{PrivacyPolicyDetails.map((item, idx) => {
					return (
						<Box key={idx}>
							<Text
								fontWeight="400"
								dangerouslySetInnerHTML={{ __html: item.desc }}
							/>

							<OrderedList mt="1rem">
								<Box>
									<ListItem fontWeight="600">{item.title}</ListItem>
									<Text fontWeight="400" mt="1rem">
										{item.subDesc}
									</Text>
									<UnorderedList>
										{item.options.map((item, idx) => {
											return (
												<Fragment key={idx}>
													<ListItem my="1rem" fontWeight="600">
														{item.subHeading}
													</ListItem>
													<Text fontWeight="400">{item.subHeadingDesc}</Text>

													<UnorderedList>
														{item.options.map((item, idx) => {
															return (
																<Fragment key={idx}>
																	<ListItem my="1rem" fontWeight="600">
																		{item.innerHeading}
																	</ListItem>
																	<Text fontWeight="400">
																		{item.innerHeadingDesc}
																	</Text>

																	<UnorderedList>
																		{item.examples.map((item, idx) => {
																			return (
																				<Fragment key={idx}>
																					<ListItem my="1rem" fontWeight="600">
																						{item.exHeading}:{" "}
																						<Text as="span" fontWeight="400">
																							{item.exHeadingDesc}
																						</Text>
																					</ListItem>
																				</Fragment>
																			);
																		})}
																	</UnorderedList>
																</Fragment>
															);
														})}
													</UnorderedList>
												</Fragment>
											);
										})}
									</UnorderedList>
								</Box>

								<Box>
									<ListItem fontWeight="600">{item.titleTwo}</ListItem>
									<Text fontWeight="400" mt="1rem">
										{item.subDescTwo}
									</Text>

									<UnorderedList mt="1rem">
										{item.optionsTwo.map((item, idx) => {
											return <ListItem key={idx}>{item.list}</ListItem>;
										})}
									</UnorderedList>
								</Box>

								<Box>
									<ListItem fontWeight="600" mt="1rem">
										{item.titleThree}
									</ListItem>
									<Text fontWeight="400" mt="1rem">
										{item.subDescThree}
									</Text>

									<UnorderedList mt="1rem">
										{item.optionsThree.slice(0, 7).map((item, idx) => {
											return <ListItem key={idx}>{item.list}</ListItem>;
										})}
									</UnorderedList>

									<Text mt="1rem">
										We may also disclose your Personal Information in the good
										faith and belief that such action is necessary in any of the
										following circumstances:
									</Text>

									<UnorderedList mt="1rem">
										{item.optionsThree.slice(7, 16).map((item, idx) => {
											return <ListItem key={idx}>{item.list}</ListItem>;
										})}
									</UnorderedList>

									<Text mt="1rem">
										We may share your information with any person or entity
										which guarantees your credit obligations to us as part of
										security requirement for a credit scheme or facility
										advanced to you.
									</Text>
								</Box>

								<Box>
									<ListItem fontWeight="600" mt="1rem">
										{item.titleFour}
									</ListItem>
									<Text
										fontWeight="400"
										mt="1rem"
										dangerouslySetInnerHTML={{ __html: item.subDescFour }}
									/>
								</Box>

								<Box>
									<ListItem fontWeight="600" mt="1rem">
										{item.titleFive}
									</ListItem>
									<Text
										fontWeight="400"
										mt="1rem"
										dangerouslySetInnerHTML={{ __html: item.subDescFive }}
									/>
								</Box>

								<Box>
									<ListItem fontWeight="600" mt="1rem">
										{item.titleSix}
									</ListItem>
									<Text fontWeight="400" mt="1rem">
										{item.subDescSix}
									</Text>

									<UnorderedList mt="1rem">
										{item.optionsSix.map((item, idx) => {
											return <ListItem key={idx}>{item.list}</ListItem>;
										})}
									</UnorderedList>

									<Text mt="1rem">
										These rights are however subject certain limitations as
										provided in the NDPR.
									</Text>
								</Box>

								<Box>
									{item.titleSeven.map((item, idx) => {
										return (
											<Fragment key={idx}>
												<ListItem fontWeight="600" mt="1rem">
													{item.title}
												</ListItem>

												<Text
													fontWeight="400"
													mt="1rem"
													dangerouslySetInnerHTML={{ __html: item.subDesc }}
												/>
											</Fragment>
										);
									})}
								</Box>
							</OrderedList>

							<Box>
								<Text fontWeight="600" mt="1rem" textDecoration="underline">
									Contact
								</Text>
								<Text>
									We are committed to resolving your privacy complaints and
									concerns as quickly as possible and have procedures in place
									to help resolve any problems or complaints efficiently. If you
									have any questions about this Privacy Policy, please contact
									us at {mail} or call {callTel}.
								</Text>
							</Box>
						</Box>
					);
				})}
			</Box>
		</Box>
	);
};

export { Privacy };
