import { MagicAttributes } from "@/components/loanModal/Modal";
import { AttributeProps, ShowModalProps } from "@/types/interfaces";
import { createContext } from "react";


export const AttributesContext = createContext<AttributeProps>({
    attribute: { product_key: null, product_id: null, },
    setAttribute: (attribute: MagicAttributes) => {
        return attribute;
    },
});


export const ShowModalContext = createContext<ShowModalProps>({
    showModal: false,
    setShowModal: (showModal: boolean) => {
        return showModal;
    }
});