import { Box, Flex, Image, Text } from "@chakra-ui/react";
import AboutUsImgOne from "@/assets/images/about-us-img1.png";
import AboutUsImgTwo from "@/assets/images/about-us-img2.png";
import AboutUsImgThree from "@/assets/images/about-us-img3.png";
import AboutUsImgFour from "@/assets/images/about-us-img4.png";
import AboutUsImgFive from "@/assets/images/about-us-img5.png";
import AboutUsImgSix from "@/assets/images/about-us-img6.png";
import AboutUsImgTwoIcon from "@/assets/images/about-us-img2-icon.png";
import AboutUsImgThreeIcon from "@/assets/images/about-us-img3-icon.png";
import AboutUsImgFourIcon from "@/assets/images/about-us-img4-icon.png";
import AboutUsImgSixIcon from "@/assets/images/about-us-img6-icon.png";

const FinancialAccess = () => {
	return (
		<Box>
			<Flex
				flexDir="column"
				fontWeight="700"
				justifyContent="center"
				alignItems="center"
				h="417px"
				ml={["-5rem", "-5rem", "-9rem", "-9rem"]}
				mt="2.5rem"
			>
				<Text fontSize={["1.5rem", "2.1rem", "3rem", "3rem", "4.5rem"]}>
					Financial access
				</Text>
				<Text
					ml="9rem"
					mt={["-0.8rem", "-1rem", "-1.5rem"]}
					fontSize={["1.5rem", "2.1rem", "3rem", "3rem", "4.5rem"]}
				>
					to
					<span style={{ color: "#9E73A8" }}> live the dream</span>
				</Text>
			</Flex>
			<Box>
				<Box>
					<Image
						src={AboutUsImgOne}
						pos="absolute"
						top="0"
						left="0"
						w={["120px", "152px", "206px"]}
						h={["auto", "auto", "280px"]}
						alt="AboutUsImgOne"
						loading="lazy"
					/>
				</Box>
				<Box display={["none", "none", "block"]}>
					<Image
						src={AboutUsImgTwo}
						pos="absolute"
						top="30px"
						left="35%"
						h="100px"
						alt="AboutUsImgTwo"
						loading="lazy"
					/>
					<Image
						top="50px"
						left="37.9%"
						src={AboutUsImgTwoIcon}
						pos="absolute"
						alt="AboutUsImgTwoIcon"
						loading="lazy"
					/>
				</Box>
				<Box>
					<Image
						src={AboutUsImgThree}
						top="60px"
						right={["35px", "35px", "35px", "110px"]}
						h={["90px", "120px", "auto"]}
						pos="absolute"
						alt="AboutUsImgThree"
						loading="lazy"
					/>
					<Image
						display={["none", "none", "block"]}
						src={AboutUsImgThreeIcon}
						pos="absolute"
						top="-7px"
						right={["-50px", "-50px", "-50px", "20px"]}
						alt="AboutUsImgThreeIcon"
						loading="lazy"
					/>
				</Box>
				<Box>
					<Image
						src={AboutUsImgFour}
						pos="absolute"
						top={["320px", "375px", "375px"]}
						left="0"
						w={["200px", "273px", "273px"]}
						alt="AboutUsImgFour"
						loading="lazy"
					/>
					<Image
						src={AboutUsImgFourIcon}
						pos="absolute"
						left={["152px", "220px", "220px"]}
						mt={["-4.5rem", "-1.5rem", "-1.5rem"]}
						alt="AboutUsImgFourIcon"
						loading="lazy"
					/>
				</Box>
				<Box display={["none", "none", "block"]}>
					<Image
						src={AboutUsImgFive}
						top="484px"
						left="50%"
						h="100px"
						pos="absolute"
						alt="AboutUsImgFive"
						loading="lazy"
					/>
				</Box>
				{/* width: 190px; height: 246px; */}
				<Box display={["none", "none", "block"]}>
					<Image
						src={AboutUsImgSix}
						top="300px"
						h={["150px", "150px", "150px", "240px"]}
						right="0"
						pos="absolute"
						alt="AboutUsImgSix"
						loading="lazy"
					/>
					<Image
						src={AboutUsImgSixIcon}
						pos="absolute"
						right="179px"
						mt="-3.1rem"
						alt="AboutUsImgSixIcon"
						loading="lazy"
					/>
				</Box>
			</Box>
		</Box>
	);
};
export { FinancialAccess };
