import { LicenseDetails } from "@/utils/Legal";
import {
	Box,
	ListItem,
	OrderedList,
	Text,
	LinkBox,
	UnorderedList,
} from "@chakra-ui/react";
import { Fragment } from "react";

const License = () => {
	const mailTo = () => {
		return (
			<Text
				as="a"
				textDecoration="underline"
				cursor="pointer"
				onClick={() => (window.location.href = "mailto:hello@snapcash.ng")}
			>
				hello@snapcash.ng
			</Text>
		);
	};
	const mail = mailTo();

	const Tel = () => {
		return (
			<LinkBox textDecoration="underline" as="a" href="tel:+2342017004271">
				02017004271
			</LinkBox>
		);
	};

	const callTel = Tel();
	return (
		<Box>
			<Text fontSize="1.5rem" fontWeight="700">
				End User License Agreement
			</Text>

			<Box>
				<Text>
					The herein contained End User License Agreement
					(&rsquo;Agreement&rsquo;) should be read carefully. It contains
					important terms that affect you and your use of the Software. By
					clicking &rsquo;I accept&rsquo; or by installing, copying, or using
					the Software, you agree to be bound by the terms of this Agreement,
					including the disclaimers. If you do not agree to these terms, do not
					download, install, copy, or use the Software.
				</Text>

				<OrderedList mt="1rem">
					{LicenseDetails.map((item, idx) => {
						return (
							<Box key={idx}>
								{item.licenceOne?.map((item, idx) => {
									return (
										<Fragment key={idx}>
											<ListItem fontWeight="600" mt="1rem">
												{item.title}
											</ListItem>

											<Text fontWeight="400" mt="1rem">
												{item.desc}
											</Text>
										</Fragment>
									);
								})}

								{item.licenceTwo?.map((item) => {
									return (
										<Fragment key={idx}>
											<ListItem fontWeight="600" mt="1rem">
												{item.title}
											</ListItem>

											<Text fontWeight="400" mt="1rem">
												{item.desc}
											</Text>

											<OrderedList>
												{item.options.map((item, idx) => {
													return (
														<Fragment key={idx}>
															<ListItem fontWeight="600" mt="1rem">
																{item.title}
															</ListItem>

															<Text fontWeight="400" mt="1rem">
																{item.desc}
															</Text>

															<OrderedList>
																{item.options.map((item, idx) => {
																	return (
																		<ListItem key={idx}> {item.list}</ListItem>
																	);
																})}
															</OrderedList>
														</Fragment>
													);
												})}
											</OrderedList>
										</Fragment>
									);
								})}

								{item.licenceThree?.map((item, idx) => {
									return (
										<Fragment key={idx}>
											<ListItem fontWeight="600" mt="1rem">
												{item.title}
											</ListItem>

											<Text fontWeight="400" mt="1rem">
												{item.desc}
											</Text>

											<OrderedList>
												{item.options.map((item, idx) => {
													return <ListItem key={idx}> {item.list}</ListItem>;
												})}
											</OrderedList>
										</Fragment>
									);
								})}

								{item.licenceFour?.map((item, idx) => {
									return (
										<Fragment key={idx}>
											<ListItem fontWeight="600" mt="1rem">
												{item.title}
											</ListItem>

											<Text fontWeight="400" mt="1rem">
												{item.desc}
											</Text>

											<Box>
												<OrderedList>
													{item.options.map((item, idx) => {
														return (
															<Fragment key={idx}>
																<ListItem> {item.list}</ListItem>
															</Fragment>
														);
													})}
												</OrderedList>
											</Box>
										</Fragment>
									);
								})}

								{item.licenceFive?.map((item, idx) => {
									return (
										<Fragment key={idx}>
											<ListItem fontWeight="600" mt="1rem">
												{item.title}
											</ListItem>

											<OrderedList>
												{item.options.map((item, idx) => {
													return (
														<Fragment key={idx}>
															<ListItem> {item.list}</ListItem>
														</Fragment>
													);
												})}

												<UnorderedList>
													<ListItem>
														{" "}
														permanently destroying all copies of the Software in
														your possession or control,
													</ListItem>
													<ListItem>
														removing the Software from any hard drive on which
														it is installed, and
													</ListItem>
													<ListItem>
														giving us Notice certifying that you have taken
														these steps.
													</ListItem>
												</UnorderedList>
											</OrderedList>
										</Fragment>
									);
								})}

								{item.licenseSix?.map((item, idx) => {
									return (
										<Fragment key={idx}>
											<ListItem fontWeight="600" mt="1rem">
												{item.title}
											</ListItem>

											<Text fontWeight="400" mt="1rem">
												{item.desc}
											</Text>

											<Box>
												<OrderedList>
													{item.options.map((item, idx) => {
														return (
															<Fragment key={idx}>
																<ListItem> {item.list}</ListItem>
															</Fragment>
														);
													})}
												</OrderedList>
											</Box>
										</Fragment>
									);
								})}
							</Box>
						);
					})}
				</OrderedList>

				<Box>
					<Text fontWeight="600" mt="1rem" textDecoration="underline">
						Contact
					</Text>
					<Text>
						If you have any questions about this Agreement, please contact us a{" "}
						{mail} or call {callTel}.
					</Text>
				</Box>
			</Box>
		</Box>
	);
};

export { License };
