import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	UnorderedList,
	Box,
	Stack,
	ListItem,
	Text,
	Tabs,
	TabList,
	Tab,
} from "@chakra-ui/react";
import { useState } from "react";
import MainLayout from "@/layouts/MainLayout";

const MerchantFaq = () => {
	const [active, setActive] = useState("first");

	const handleActivateState = (position: string) => {
		switch (position) {
			case "first":
				setActive("first");
				break;
			case "second":
				setActive("second");
				break;
			case "third":
				setActive("third");
				break;
			case "fourth":
				setActive("fourth");
				break;
			default:
				setActive("first");
		}
	};
	return (
		<MainLayout>
			<Box p="0" bg="brand.purple100">
				<Stack
					maxW="1280px"
					mx="auto"
					p={["0rem 1rem 2rem", "2rem 2rem 2rem", "4rem 3rem 4rem"]}
				>
					<Box>
						<Text
							fontSize={["2rem", "2rem", "3rem"]}
							color="brand.white100"
							fontWeight="700"
						>
							SnapPurse FAQs
						</Text>
					</Box>
				</Stack>
			</Box>

			<Stack
				maxW="1280px"
				mx="auto"
				p={["0rem 1rem 0rem", "2rem 2rem 0rem", "6rem 3rem 4rem"]}
			>
				<Box
					display="flex"
					flexDir={["column", "column", "row"]}
					justifyContent="space-between"
				>
					<Accordion
						w={["100%", "100%", "50%"]}
						order={["1", "1", "0"]}
						mb={["2rem", "2rem", "0"]}
						allowMultiple
					>
						<AccordionItem
							border="2px solid #000"
							borderRadius="12px"
							p="1rem 1rem"
							order="1"
						>
							<h2>
								<AccordionButton
									fontSize={["1rem", "1rem", "1rem", "2rem"]}
									_hover={{
										bg: "none",
									}}
									_focus={{
										boxShadow: "none",
									}}
								>
									<Box
										id="first"
										flex="1"
										textAlign="left"
										fontWeight="700"
										onClick={() => handleActivateState("first")}
										color={
											active === "first" ? "brand.purple100" : "brand.dark100"
										}
									>
										What is SnapPurse?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel
								pb={4}
								fontSize={[".85rem", ".85rem", ".85rem", "1rem"]}
							>
								SnapPurse is a fast, simple and secure way to collect payments
								from your customers. It will transform the way you do business
								as it is seamless, and you can collect payments from anyone no
								matter what bank they use.
							</AccordionPanel>
						</AccordionItem>

						<AccordionItem
							border="2px solid #000"
							borderRadius="12px"
							p="1rem 1rem"
							mt="2.5rem"
						>
							<h2>
								<AccordionButton
									fontSize={["1rem", "1rem", "1rem", "2rem"]}
									_hover={{
										bg: "none",
									}}
									_focus={{
										boxShadow: "none",
									}}
								>
									<Box
										flex="1"
										textAlign="left"
										fontWeight="700"
										onClick={() => handleActivateState("second")}
										color={
											active === "second" ? "brand.purple100" : "brand.dark100"
										}
									>
										How does it work?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel
								pb={4}
								fontSize={[".85rem", ".85rem", ".85rem", "1rem"]}
							>
								After selling, have your customer scan your QR code and you will
								get paid instantly. Kindly note that you can receive payments
								from any Nigerian bank app as long as it has the NQR feature
								enabled.
							</AccordionPanel>

							<AccordionPanel
								pb={4}
								fontSize={[".85rem", ".85rem", ".85rem", "1rem"]}
							>
								Alternatively, your customers can simply transfer to the account
								number displayed on your SnapPurse code and you will receive the
								money within ten (10) seconds.
							</AccordionPanel>
						</AccordionItem>

						<AccordionItem
							border="2px solid #000"
							borderRadius="12px"
							p="1rem 1rem"
							mt="2.5rem"
						>
							<h2>
								<AccordionButton
									fontSize={["1rem", "1rem", "1rem", "2rem"]}
									_hover={{
										bg: "none",
									}}
									_focus={{
										boxShadow: "none",
									}}
								>
									<Box
										flex="1"
										textAlign="left"
										fontWeight="700"
										onClick={() => handleActivateState("third")}
										color={
											active === "third" ? "brand.purple100" : "brand.dark100"
										}
									>
										What is a QR code?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel
								pb={4}
								fontSize={[".85rem", ".85rem", ".85rem", "1rem"]}
							>
								A QR code is a payment barcode unique to your business, which
								your customers can simply scan from their mobile phones to pay
								you instantly.
							</AccordionPanel>
						</AccordionItem>

						<AccordionItem
							border="2px solid #000"
							borderRadius="12px"
							p="1rem 1rem"
							mt="2.5rem"
						>
							<h2>
								<AccordionButton
									fontSize={["1rem", "1rem", "1rem", "2rem"]}
									id="fourth"
									_hover={{
										bg: "none",
									}}
									_focus={{
										boxShadow: "none",
									}}
								>
									<Box
										flex="1"
										textAlign="left"
										fontWeight="700"
										onClick={() => handleActivateState("fourth")}
										color={
											active === "fourth" ? "brand.purple100" : "brand.dark100"
										}
									>
										What are the benefits of using the SnapPurse?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel
								pb={4}
								fontSize={[".85rem", ".85rem", ".85rem", "1rem"]}
							>
								<UnorderedList>
									<ListItem my="1rem" fontWeight="600">
										No cash? No problem!
									</ListItem>
									<Text>
										The SnapPurse ensures you never miss a sale because your
										customer doesn’t have cash, or they forgot their wallets at
										home. This means more sales and more profit for your
										business.
									</Text>

									<ListItem my="1rem" fontWeight="600">
										Enjoy fast payments
									</ListItem>
									<Text>
										The SnapPurse app opens within seconds so your customers can
										pay you instantly without any delays, this way you can close
										a sale quickly and go attend to other customers. If they use
										the transfer option, you will get your money within ten (10)
										seconds.
									</Text>

									<ListItem my="1rem" fontWeight="600">
										Setting up is as simple as ABC
									</ListItem>
									<Text>
										Just download the app from&#160;
										<a
											href="https://apps.apple.com/ng/app/snapcash-loans-in-a-snap/id1634947408"
											target="_blank"
											rel="noreferrer"
										>
											<span style={{ color: "#9E73A8" }}>
												Google Play Store
											</span>
										</a>
										&#160;or the&#160;
										<a
											href="https://play.google.com/store/apps/details?id=com.snapcashsterling"
											target="_blank"
											rel="noreferrer"
										>
											<span style={{ color: "#9E73A8" }}>App Store</span>
										</a>
										&#160;and set up your SnapPurse merchant profile within
										seconds and at zero cost.
									</Text>

									<ListItem my="1rem" fontWeight="600">
										It is safe and secure
									</ListItem>
									<Text>
										The SnapPurse app is designed with the latest QR technology;
										it is safe and secure. The payments go straight to your
										merchant account, so you can rest easy and enjoy peace of
										mind.
									</Text>

									<ListItem my="1rem" fontWeight="600">
										You can use it offline
									</ListItem>
									<Text>
										The SnapPurse app will still open and display your QR code
										even when there is no internet connection. Getting paid has
										never been this easy.
									</Text>
								</UnorderedList>
							</AccordionPanel>
						</AccordionItem>
					</Accordion>

					<Tabs orientation="vertical" w={["100%", "100%", "30%"]}>
						<TabList
							h={["39.5%", "39.5%", "39.5%", "37.5%", "34%"]}
							borderColor="brand.dark100"
							my="2rem"
						>
							<Tab
								justifyContent="start"
								onClick={() => handleActivateState("first")}
								fontWeight="500"
								fontSize={[".8rem", ".8rem", ".8rem", "1.2rem"]}
								textAlign="left"
								color={active === "first" ? "brand.purple100" : "brand.dark100"}
								_focus={{
									boxShadow: "none",
								}}
								_active={{
									bg: "none",
								}}
								_selected={{
									color: "brand.purple100",
									borderColor: "brand.purple100",
									borderLeftWidth: "3.5px",
									// h: "12px",
								}}
							>
								What is SnapPurse?
							</Tab>

							<Tab
								justifyContent="start"
								onClick={() => handleActivateState("second")}
								fontWeight="500"
								fontSize={[".8rem", ".8rem", ".8rem", "1.2rem"]}
								textAlign="left"
								color={
									active === "second" ? "brand.purple100" : "brand.dark100"
								}
								_focus={{
									boxShadow: "none",
								}}
								_active={{
									bg: "none",
								}}
								_selected={{
									color: "brand.purple100",
									borderColor: "brand.purple100",
									borderLeftWidth: "3.5px",
									// h: "12px",
								}}
							>
								How does it work?
							</Tab>
							<Tab
								justifyContent="start"
								onClick={() => handleActivateState("third")}
								fontWeight="500"
								fontSize={[".8rem", ".8rem", ".8rem", "1.2rem"]}
								textAlign="left"
								color={active === "third" ? "brand.purple100" : "brand.dark100"}
								_focus={{
									boxShadow: "none",
								}}
								_active={{
									bg: "none",
								}}
								_selected={{
									color: "brand.purple100",
									borderColor: "brand.purple100",
									borderLeftWidth: "3.5px",
									// h: "12px",
								}}
							>
								What is a QR code?
							</Tab>
							<Tab
								justifyContent="start"
								onClick={() => handleActivateState("fourth")}
								fontWeight="500"
								fontSize={[".8rem", ".8rem", ".8rem", "1.2rem"]}
								textAlign="left"
								color={
									active === "fourth" ? "brand.purple100" : "brand.dark100"
								}
								_focus={{
									boxShadow: "none",
								}}
								_active={{
									bg: "none",
								}}
								_selected={{
									color: "brand.purple100",
									borderColor: "brand.purple100",
									borderLeftWidth: "3.5px",
									// h: "12px",
								}}
							>
								What are the benefits of using the SnapPurse?
							</Tab>
						</TabList>
					</Tabs>
				</Box>
			</Stack>
		</MainLayout>
	);
};

export default MerchantFaq;
