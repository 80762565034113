// import Logo from "@/assets/svgs/snapcash-header-logo.webp";
import ArrowDownWhite from "@/assets/svgs/arrow-down-white-icon.svg";
import ArrowUpWhite from "@/assets/svgs/arrow-up-white-icon.svg";
import { HeaderLayout } from "@/components/layout/HeaderLayout";
import Logo from "@/assets/images/snapcash-header-logo.webp";

const Header = () => {
	return (
		<HeaderLayout
			bg="brand.purple100"
			src={Logo}
			color="brand.white100"
			arrowDown={ArrowDownWhite}
			arrowUp={ArrowUpWhite}
		/>
	);
};
export { Header };
