import { Box, Grid, GridItem, Image, Text } from "@chakra-ui/react";
import AccessBellIcon from "@/assets/images/access-img-bell.png";
import DocumentIcon from "@/assets/images/document-img-icon.png";
import AgreeableIcon from "@/assets/images/about-us-key.png";

export const PersonalLoan = () => {
	return (
		<Box
			borderRadius="20px"
			bg="brand.purple100"
			p={["1.5rem", "1.5rem", "3rem", "3.5rem", "5rem"]}
		>
			<Box w={["100%", "100%", "90%", "100%", "90%"]}>
				<Text
					fontWeight="700"
					fontSize={["1.5rem", "1.8rem", "2.5rem", "3rem", "4.3rem"]}
				>
					Access to personal loans, payday loans
				</Text>
			</Box>

			<Grid
				templateColumns={[
					"repeat(1, 1fr)",
					"repeat(1, 1fr)",
					"repeat(1, 1fr)",
					"repeat(2, 1fr)",
					"repeat(3, 1fr)",
				]}
				gap={3}
				mt="4rem"
			>
				<GridItem h="100%">
					<Box
						bg="brand.white100"
						borderRadius="12px"
						h="60px"
						w="60px"
						pos="relative"
						ml="1rem"
					>
						<Image
							src={AccessBellIcon}
							maxW="100px"
							pos="absolute"
							top="-1.5rem"
							left="-2rem"
							alt="Bell Icon"
							loading="lazy"
						/>
					</Box>

					<Box py="1.5rem">
						<Text
							fontWeight="700"
							fontSize={["1.3rem", "1.5rem", "1.75rem", "1.75rem", "1.75rem"]}
							lineHeight="1"
							maxW="250px"
						>
							Access easy cash in a snap
						</Text>
						<Text
							fontWeight="500"
							fontSize={["1rem", "1.1rem", "1.1rem", "1.1rem"]}
							pt="2rem"
							lineHeight="1.2"
							maxW="260px"
						>
							Get swift loan approval and access your loan in less than 5
							minutes.
						</Text>
					</Box>
				</GridItem>

				<GridItem h="100%">
					<Box
						bg="brand.white100"
						borderRadius="12px"
						h="60px"
						w="60px"
						pos="relative"
						ml="1rem"
					>
						<Image
							src={DocumentIcon}
							maxW="100px"
							pos="absolute"
							top="-2rem"
							left="-2.5rem"
							alt="Document Icon"
							loading="lazy"
						/>
					</Box>

					<Box py="1.5rem">
						<Text
							fontWeight="700"
							fontSize={["1.3rem", "1.5rem", "1.75rem", "1.75rem", "1.75rem"]}
							lineHeight="1"
							maxW="250px"
						>
							No bulky documentation
						</Text>
						<Text
							fontWeight="500"
							fontSize={["1rem", "1.1rem", "1.1rem", "1.1rem"]}
							pt="2rem"
							lineHeight="1.2"
							maxW="260px"
						>
							Take care of your personal needs with SnapCash loans, with as
							little as a valid identification document.
						</Text>
					</Box>
				</GridItem>

				<GridItem h="100%">
					<Box
						bg="brand.white100"
						borderRadius="12px"
						h="60px"
						w="60px"
						pos="relative"
						ml="1rem"
					>
						<Image
							src={AgreeableIcon}
							maxW="100px"
							pos="absolute"
							top="-0.7rem"
							left="-0.5rem"
							alt="Agreeable Icon"
							loading="lazy"
						/>
					</Box>

					<Box py="1.5rem">
						<Text
							fontWeight="700"
							fontSize={["1.3rem", "1.5rem", "1.75rem", "1.75rem", "1.75rem"]}
							lineHeight="1"
							maxW="260px"
						>
							Agreeable interest rates, all for you
						</Text>
						<Text
							fontWeight="500"
							fontSize={["1rem", "1.1rem", "1.1rem", "1.1rem"]}
							pt="2rem"
							lineHeight="1.2"
							maxW="260px"
						>
							Enjoy financial freedom with the great interest rates we offer on
							any loan you take.
						</Text>
					</Box>
				</GridItem>
			</Grid>
		</Box>
	);
};
