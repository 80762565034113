import { Box } from "@chakra-ui/react";
import {
	Access,
	Agreeable,
	Airtime,
	Document,
} from "@/components/home/Access";
import { Hero } from "@/components/home/hero/Hero";
import { HowItWorks } from "@/components/home/HowItWorks";
import { Plan } from "@/components/home/plan/Plan";
import MainLayout from "@/layouts/MainLayout";

const Home = () => {
	return (
		<Box p="0">
			<MainLayout>
				<Hero />
				<Plan />
				<Access />
				<Document />
				<Agreeable />
				<Airtime />
				<HowItWorks />
			</MainLayout>
		</Box>
	);
};

export default Home;
